import React from "react";
import { getIcon } from "../../Utility/getImg";

import "./styles.scss";

type Props = {
  context: any;
  btnText?: string;
  onClick?: Function;
};

function DashboardHeader(props: Props) {
  return (
    <div className="dashbord-header-container">
      {props.btnText ? (
        <button
          className="dashbord-header-btn"
          onClick={() => {
            if (props.onClick) props.onClick();
          }}
        >
          {props.btnText}
        </button>
      ) : (
        <div></div>
      )}

      <div className="dashbord-header-right">
        {/* <img
          src={getIcon("notification.svg")}
          alt="notification-icon"
          className="dashbord-header-icon"
        /> */}
        {/* <img
          src={getIcon("settings.svg")}
          alt="settings-icon"
          className="dashbord-header-icon"
        /> */}
        <img
          className="dashbord-header-avatar"
          src="https://reqres.in/img/faces/9-image.jpg"
        />
      </div>
    </div>
  );
}

export default DashboardHeader;
