import React, { useState } from "react";
import { Link } from "react-router-dom";
import { errorHandler, resetPass } from "../../APIs/API";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

type Props = {
  context: any;
  changeHistory: Function;
};

export default function ResetPass(props: Props) {
  const [pass, setPass] = useState("");
  const [rePass, setRePass] = useState("");

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <FadeInSection>
      <div className="koha-login">
        <div className="kl-header">Enter your new password</div>
        <div className="kl-body">
          <div>Password</div>
          <input
            id={"kohaMail"}
            type="text"
            value={pass}
            onChange={(ev) => setPass(ev.target.value)}
          />
          <div>Re-enter password</div>
          <input
            id={"kohaMail"}
            type="text"
            value={rePass}
            onChange={(ev) => setRePass(ev.target.value)}
          />

          <div
            onClick={() => {
              const params = new URLSearchParams(window.location.search);
              if (params.has("email")) {
                let email = params.get("email") || "";
                resetPass(false, email, pass)
                  .then((res: any) => {
                    if (res.status === 201) {
                      props.context.setItem("toasterText", "Password changed.");
                      setTimeout(() => {
                        props.changeHistory("/");
                      }, 1000);
                    } else {
                      props.context.setItem(
                        "toasterText",
                        "Something went wrong, please try again later."
                      );
                    }
                  })
                  .catch((err: any) => {
                    errorHandler(
                      err,
                      props.context.setItem(
                        "toasterText",
                        "Something went wrong, please try again later."
                      )
                    );
                  });
              }
            }}
            className="login-but clickable"
          >
            <span>Reset Password</span>
          </div>
        </div>
      </div>
    </FadeInSection>
  );
}
