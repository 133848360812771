import React from "react";
import { FaCubes } from "react-icons/fa";

type Props = {
  onClick: Function;
  text: string;
  floater?: boolean;
};

export const PixelateButton = (props: Props) => {
  return (
    <div
      onClick={() => props.onClick()}
      className={`pixelate-button ${props.floater ? "chatbot-style" : ""}`}
    >
      <FaCubes size={props.floater ? "26px" : ""} />
      <div id={"pbText"}>{props.text}</div>
    </div>
  );
};
