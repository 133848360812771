const sidebar_menu = [
  // {
  //     id: 1,
  //     icon: "dashboard.svg",
  //     path: '/admin-panel',
  //     title: 'Dashboard',
  // },
  {
    id: 2,
    icon: "product.svg",
    path: "/admin-panel/orders",
    title: "Orders",
  },
  {
    id: 3,
    icon: "shipping.svg",
    path: "/admin-panel/products",
    title: "Products",
  },
  {
    id: 4,
    icon: "user.svg",
    path: "/admin-panel/users",
    title: "Users",
  },
  {
    id: 5,
    icon: "coupon.svg",
    path: "/admin-panel/coupons",
    title: "Coupons",
  },
  {
    id: 6,
    icon: "admin.svg",
    path: "/admin-panel/admins",
    title: "Admins",
  },
  {
    id: 7,
    icon: "blog.svg",
    path: "/admin-panel/blog",
    title: "Blog",
  },
];

export default sidebar_menu;
