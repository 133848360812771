export type faqItem = {
  question: string;
  answer: string;
};

export const pixelate: faqItem[] = [
  {
    question: "How big can i go?",
    answer:
      "You can add up to 20 plates in the horizontal and vertical direction using our Pixelate Tool. That is pretty big!!! Want to go bigger? Contact us and we will provide a custom quote.",
  },
  {
    question: "How many colors does the pixelate palette have?",
    answer:
      "The palette has 50 colours. Most pictures look just as good with 20 colours. You are given the freedom to  add or remove any colours of your choice during customization. Get Creative! Get Artistic! #yourphotoyourway!.",
  },
  {
    question: "What are the dimensions?",
    answer:
      "Each plate measures 25.6*25.6 cm. The more plates you add, the larger it gets. Don’t worry! Our tool does the math for you and tells you the dimensions.",
  },
  {
    question: "Can i use any image?",
    answer: "Yes, you can, if it adheres to our Terms & Conditions",
  },
  // {
  //   question: "How can  I improve the resolution?",
  //   answer:
  //     "The resolution of the image can be improved by increasing the number of base plates. Images with intricate details will appear pixelated in small configurations. Hence we recommend choosing images with fewer details (for example close up image of one’s face) for small configurations and large configurations for images with loads of details. We recommend using our crop tool to give preference to important areas and hence improve the resolution.<br/>Use our preview option to see how your image looks when mounted. Take a step back and enjoy the beauty of your Pixelate.",
  // },
  {
    question: "Can i build it myself?",
    answer: "You sure can! Contact us and we will get it sorted for you!",
  },
  {
    question: "Are the plates connected to each other?",
    answer: " No, they are not",
  },
];

export const trickrtreat: faqItem[] = [
  {
    question: "How do i mount/hang the painting?",
    answer: `Your pre-assembled package of happiness comes with few mounting screws.

Drill two holes into the walls after making appropriate measurements. Tap the provided plastic anchor into it and screw the screw into it. Leave a bit of the screw to protrude so that you can hang your statement piece.

We recommend mounting Pixelate away from direct white led light to avoid any glare. `,
  },
  {
    question: "Lighting",
    answer: `Make sure the subject is in a well-lit ambient environment with good lighting and the face is free off any shadows.`,
  },
  {
    question: "Contrast",
    answer: `Make sure there is enough contrast between the subject and the background to prevent any mix up between the two. Hard to understand? That’s fine, have a look at the gallery images.`,
  },
  {
    question: "Pets",
    answer: `We are pretty sure your camera roll is full off your pet doing adorable stuffs in awkward positions. Make sure to use photos where your pets are smiling at the camera for great results.`,
  },
  {
    question: "Details",
    answer: `For pictures with loads of details, try adding more baseplates to get good results. Also, play around with our zoom and auto-fit feature to improve the results.
    Bonus Tip – For group photos, we recommend selecting photos where the subjects are close to each other.`,
  },
];

export const product: faqItem[] = [
  {
    question: "How do i mount/hang the painting?",
    answer: `Your pre-assembled package of happiness comes with few mounting screws.

Drill two holes into the walls after making appropriate measurements. Tap the provided plastic anchor into it and screw the screw into it. Leave a bit of the screw to protrude so that you can hang your statement piece.

We recommend mounting Pixelate away from direct white led light to avoid any glare. `,
  },
];

export const shipping: faqItem[] = [
  {
    question: "How can i track my order?",
    answer:
      "Once your Pixelate order has been shipped, we will email you tracking id and the link. Please check your inbox from time to time.",
  },
  {
    question: "How long does it take for my order to arrive?",
    answer:
      "Orders usually leave our warehouse 2-3days from the date of purchase. For more information, please check out our Shipping Information Page.",
  },
  {
    question: "Do we deliver internationally?",
    answer: "Yes, we do. Contact us for more information.",
  },
  // {
  //   question: "What are the shipping prices?",
  //   answer:
  //     "Tricky question! Shipping rates are subject to change based on the size of your order and delivery location. Don’t worry! This is calculated at the checkout. ",
  // },
];

export const privacy: faqItem[] = [
  {
    question: "Are any of my images stored?",
    answer:
      "We place the utmost value on our clients' privacy. We follow our stringent data protection guidelines. The original and the pixelated image are stored for a period of 10 days from the date of delivery and then deleted from our database.",
  },
];

export const returns: faqItem[] = [
  {
    question: "What if my product gets damaged during Shipping?",
    answer: "Excellent Question! Please have a look at our Return Policy.",
  },
];

export const contact: faqItem[] = [
  {
    question: "How can i get in touch with the team?",
    answer:
      "You can contact us using the Contact Us form or by writing to us at info@thekoha.com",
  },
];
