import React, { useState, useEffect } from "react";
import {
  addProduct,
  itemSizeType,
  getSignedRequest,
  itemDetailsType3,
  updateProduct,
} from "../../../APIs/API";
import { IoClose } from "react-icons/io5";

type Props = {
  context: any;
  data?: itemDetailsType3;
  refresh: Function;
};

const AddProduct = (props: Props) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [clip, setClip] = useState<any>(null);
  const [sizes, setSizes] = useState<itemSizeType[]>([]);

  useEffect(() => {
    if (props.data) {
      setName(props.data.name);
      setClip(props.data.item_link);
      setDesc(props.data.description || "");
      let temp = Object.values(props.data.sizes);
      setSizes([...temp]);
    }
  }, []);

  //   React.useEffect(() => {
  //     console.log("hello");
  //   }, [sizes]);

  const addSize = () => {
    let sizeTemp = sizes;
    let temp: itemSizeType = {
      size: "",
      image: null,
      dimensions: {
        x: 1,
        y: 1,
      },
    };

    sizeTemp.push(temp);
    setSizes([...sizeTemp]);
    console.log(sizes);
  };

  const removeSize = (index: number) => {
    let sizeTemp = sizes;
    sizeTemp.splice(index, 1);
    setSizes([...sizeTemp]);
  };

  const updateSize = (index: number, val: any, key: string) => {
    let sizeTemp = sizes;
    let temp = sizeTemp[index];
    switch (key) {
      case "size":
      case "image":
        temp[key] = val;
        break;
      case "x":
        temp.dimensions["x"] = val;
        break;
      case "y":
        temp.dimensions["y"] = val;
        break;
      default:
        break;
    }
    sizeTemp[index] = temp;
    setSizes([...sizeTemp]);
  };

  function addItem() {
    if (name && clip && sizes.length !== 0) {
      let data = {
        itemid: props.data ? props.data.itemid : 0,
        item_link: clip,
        price: 0,
        quantity: 0,
        status: true,
        sizes: {
          ...sizes,
        },
        name: name,
        description: desc,
      };
      if (props.data) {
        updateProduct(data).then(() => {
          props.context.setItem("toasterText", "Product has been updated");
          props.refresh();
          props.context.setOverlay(false);
        });
      } else {
        addProduct(data).then(() => {
          props.context.setItem("toasterText", "Product has been added");
          props.refresh();
          props.context.setOverlay(false);
        });
      }
    } else {
      props.context.setItem("toasterText", "Please fill in all details");
    }
  }

  return (
    <div className="add-modal">
      <div className="add-modal-label">Item name:</div>
      <input
        type="text"
        name="Product name"
        id=""
        value={name}
        onChange={(ev) => setName(ev.target.value)}
      />
      <div className="add-modal-label">Item description:</div>
      <textarea
        name="Product name"
        id=""
        value={desc}
        onChange={(ev) => setDesc(ev.target.value)}
      />
      <div className="add-modal-label">Item image:</div>
      <div className="flexed ali-centered">
        <img id="clip" src={clip + "?" + performance.now()} alt="" />
        <input
          type="file"
          name="Product img"
          onChange={async function (ev) {
            if (ev.target.files) {
              let el = document.getElementById("clip") as HTMLImageElement;

              getSignedRequest(ev.target.files[0])
                .then((data) => {
                  setClip(data + "?" + performance.now());
                })
                .catch((e) => {
                  props.context.setItem(
                    "toasterText",
                    "Image was not uploaded. Please try again later."
                  );
                });
            }
          }}
          id=""
        />
      </div>

      <div className="add-modal-label">Item sizes:</div>
      <div className="add-modal-size-list styledScroll">
        {sizes.map((e: itemSizeType, i: number) => {
          return (
            <div
              className="add-modal-each-size"
              key={i}
              style={i !== 0 ? { borderTop: "solid 1px #00000033" } : {}}
            >
              <div
                className="remove-size clickable"
                onClick={() => {
                  removeSize(i);
                }}
              >
                <IoClose size={"12px"} />
              </div>
              <div className="add-modal-label">Size title:</div>

              <input
                type="string"
                onChange={(ev) => {
                  updateSize(i, ev.target.value, "size");
                }}
                value={e.size}
                name="Size title"
                id=""
              />
              <div className="add-modal-label">Size image:</div>
              <div className="flexed ali-centered">
                <img id={`sizeClip${i}`} src={e.image} alt="" />
                <input
                  type="file"
                  name="Size img"
                  id=""
                  onChange={async function (ev) {
                    if (ev.target.files) {
                      getSignedRequest(ev.target.files[0])
                        .then((data) => {
                          updateSize(
                            i,
                            data + "?" + performance.now(),
                            "image"
                          );
                        })
                        .catch((e) => {
                          props.context.setItem(
                            "toasterText",
                            "Image was not uploaded. Please try again later."
                          );
                        });
                    }
                  }}
                />
              </div>

              <div className="add-modal-label">Size Dimensions:</div>
              <div className="size-value">
                Horizontal baseplate size:
                <input
                  type="number"
                  min={1}
                  max={10}
                  name="Size x"
                  value={e.dimensions.x}
                  id=""
                  onChange={(ev) => {
                    updateSize(i, ev.target.value, "x");
                  }}
                />
              </div>
              <div className="size-value">
                Vertical baseplate size:
                <input
                  type="number"
                  min={1}
                  max={10}
                  name="Size y"
                  value={e.dimensions.y}
                  id=""
                  onChange={(ev) => {
                    updateSize(i, ev.target.value, "y");
                  }}
                />
              </div>
            </div>
          );
        })}
        <div
          className="add-modal-add-size black-button clickable"
          onClick={() => {
            addSize();
          }}
        >
          + Add size
        </div>
      </div>

      <div
        className="black-button"
        onClick={() => {
          addItem();
        }}
      >
        {props.data ? "Update" : "Add"}
      </div>
    </div>
  );
};

export default AddProduct;
