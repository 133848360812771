import { useEffect, useState } from "react";
import { getAllProducts, itemDetailsType3 } from "../../APIs/API";
import GalleryItem from "./GalleryItem";

type Props = {
  context: any;
  changeHistory: Function;
  reloadItem?: Function;
};

function SuggestionStrip(props: Props) {
  const [strip, setStrip] = useState([]);

  useEffect(() => {
    getAllProducts().then((res) => {
      if (res.data.success === true) {
        setStrip(res.data.data.sort(() => Math.random() - 0.5).slice(0, 3));
      }
    });
  }, []);

  return (
    <div className="suggestion-strip">
      <div className="suggestion-strip-header">
        Here are some items you may be interested in:
      </div>
      <div className="suggestion-strip-content styledScroll">
        {strip.map((e: itemDetailsType3, i: number) => {
          return (
            <GalleryItem
              changeHistory={props.changeHistory}
              data={e}
              key={i}
              onClick={() => {
                props.context.setItemDetails(e);
                if (props.reloadItem) {
                  props.reloadItem(e);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SuggestionStrip;
