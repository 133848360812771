import React from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

type Props = {};

export const Returns = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="para-header">Return policy </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Return Policy</h3>
          <p>
            We at Koha ensure that all the products meet our high-quality
            standards. The orders are examined carefully for any damages, packed
            with utmost care and shipped off to decorate your walls. We offer
            replacement of the product if:
            <ul>
              <li>A wrong product has been dispatched </li>
              <li>If the product is received in a damaged condition.</li>
            </ul>
            In order to send the product back safely, please ensure you have the
            original packaging material with you. To initiate a return, please
            get in touch with us by sending an email to support@thekoha.com and
            include photos to show the issue. We do not process refunds in any
            case.
          </p>
        </div>
      </FadeInSection>

      {/* <FadeInSection>
        <div className="static-para">
          <h3>Products purchased from the Koha Gallery</h3>
          <p>
            If for any reason you are not satisfied with your purchase, you can
            return the product(s) for a full refund or exchange provided the
            below conditions are met <br />
            - Product must be unused and in its original packaging.
            <br />
            - Product must not be damaged.
            <br />
            - Proof of Purchase must be presented, and return should be within
            10 days of purchase.
            <br />
            All returns will be inspected to ensure all the above criteria are
            met. In the event of the above conditions not being met, we reserve
            the right not to issue a refund. All return requests should include
            pictures of the product and a clear description of the issue.
          </p>
        </div>
      </FadeInSection> */}

      <FadeInSection>
        <div className="static-para">
          <h3>Cancellation</h3>
          <p>
            Due to the customized nature of the product and since the products
            are built to order, we cannot offer you a cancellation.
          </p>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Return Process</h3>
          <p>
            <ol type="1">
              <li>
                Our return policy is applicable only for the following reasons:
                <ul>
                  <li>The product is damaged during shipping.</li>
                  <li>Wrong product has been dispatched.</li>
                </ul>
                Products can be returned within 10 days from the date of
                delivery as per the confirmation received from our courier
                partners. You can reach us at support@thekoha.com to initiate a
                return. Please include photos in the email and describe the
                issue.
              </li>

              <li>
                We will then get back to you with your Return Status within 2-3
                days of receiving your request mail with photos . Once your
                return request has been accepted, we will arrange a reverse pick
                up of the product. It usually takes about 2-3 working days for
                organizing a pickup and 4-6 days for delivery at our warehouse.
                Once the product is back at our warehouse, we will inspect the
                product and initiate the replacement process. Once the
                replacement product leaves our warehouse, we will email you the
                shipment details.
              </li>
            </ol>
            Please make sure the product is sent back to us in its original
            packaging and the product is in unused condition. You will be asked
            to print 2 documents to be provided to our courier partner
            representative who comes for the pickup.
          </p>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Contact Us</h3>
          <p>
            If you would like to raise any other concerns regarding your
            purchase, feel free to contact us by sending an email to
            "support@thekoha.com"
          </p>
        </div>
      </FadeInSection>
    </div>
  );
};
