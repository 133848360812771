import React, { useEffect, useState } from "react";
import { FadeInSection } from "./FadeInSection";

type Props = { text: string; clearText: Function };

const Toaster = (props: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.text ? true : false);

    const interval = setInterval(() => {
      setShow(false);
      setTimeout(() => {
        props.clearText();
      }, 200);
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, [props.text]);

  return (
    <div className={`toaster-holder ${show ? "visible" : ""}`}>
      <div className="toaster-body">{props.text}</div>
    </div>
  );
};

export default Toaster;
