export function getImg(img) {
  let imgUrl = null;
  try {
    imgUrl = require(`../../../assets/images/${img}`);
  } catch (e) {
    imgUrl = require(`../../../assets/images/${img.replace(
      /\/\d{1,}.svg$/g,
      "/1.svg"
    )}`);
  }
  return imgUrl;
}

export function getFile(file) {
  let fileUrl = null;
  try {
    fileUrl = require(`../../../assets/files/${file}`);
  } catch (e) {
    fileUrl = require(`../../../assets/files/${file.replace(
      /\/\d{1,}.pdf$/g,
      "/1.pdf"
    )}`);
  }
  return fileUrl;
}

export function getIcon(icon) {
  let iconUrl = null;
  try {
    iconUrl = require(`../../../assets/icons/${icon}`);
  } catch (e) {
    iconUrl = require(`../../../assets/icons/${icon.replace(
      /\/\d{1,}.svg$/g,
      "/1.svg"
    )}`);
  }
  return iconUrl;
}

export function imageExists(image_url) {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status != 404;
}

// export function getVid(img) {
//   let imgUrl = null;
//   try {
//     imgUrl = require(`../../../assets/temp/${img}`);
//   } catch (e) {
//     imgUrl = require(`../../../assets/temp/${img.replace(
//       /\/\d{1,}.mp4$/g,
//       "/1.mp4"
//     )}`);
//   }
//   return imgUrl;
// }
