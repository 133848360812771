import { itemDetailsType3 } from "../../APIs/API";

type Props = {
  data: itemDetailsType3;
  onClick: Function;
  changeHistory: Function;
};

const GalleryItem = (props: Props) => {
  return (
    <div className="gallery-item">
      <img src={props.data.item_link} alt="" />
      <div className="gi-name" title={props.data.name}>
        {props.data.name}
      </div>
      <span style={{ textAlign: "center", fontSize: "10px" }}>
        from ₹
        {props.data.sizes[0].dimensions.x *
          props.data.sizes[0].dimensions.y *
          1900}
      </span>

      <div
        className="black-button"
        onClick={() => {
          props.onClick();
          if (window.location.pathname.indexOf("itemdetails") === -1) {
            props.changeHistory("/itemdetails");
          }
        }}
      >
        View details
      </div>
    </div>
  );
};

export default GalleryItem;
