import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getIcon } from "../../Utility/getImg";

import "./styles.scss";

type Props = { item: any; active: boolean };

const SideBarItem = (props: Props) => {
  const [hover, setHover] = useState(false);
  return (
    <Link
      to={props.item.path}
      className={props.active ? "sidebar-item-active" : "sidebar-item"}
    >
      <img
        src={getIcon(`${props.item.icon}`)}
        alt={`icon-${props.item.icon}`}
        className="sidebar-item-icon"
      />
      <span className="sidebar-item-label">{props.item.title}</span>
    </Link>
  );
};
export default SideBarItem;
