import * as React from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";
import GalleryItem from "./GalleryItem";
import SuggestionStrip from "./SuggestionStrip";
import { getAllProducts, itemDetailsType3 } from "../../APIs/API";

export interface IProps {
  context: any;
  changeHistory: Function;
}

export interface IState {
  galleryItem: itemDetailsType3[];
}

export default class Gallery extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      galleryItem: [],
    };
  }

  componentDidMount(): void {
    getAllProducts().then((res) => {
      if (res.data.success === true) {
        this.setState({
          galleryItem: res.data.data,
        });
      }
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  public render() {
    return (
      <div className="koha-gallery koha-static">
        <FadeInSection>
          <div className="page-img-headers">
            <img src={getImg("shop-banner.png")} />
            <div className="overlay-text ">
              <div className="para-header">Koha gallery</div>
              <div style={{ color: "white", fontStyle: "italic" }}>
                Visually inspiring. Uniquely artistic.
              </div>
            </div>
          </div>
        </FadeInSection>
        <div className="koha-shop">
          {this.state.galleryItem.map((e: itemDetailsType3, i: number) => {
            return (
              <GalleryItem
                data={e}
                key={i}
                onClick={() => {
                  this.props.context.setItemDetails(e);
                }}
                changeHistory={this.props.changeHistory}
              />
            );
          })}
        </div>
        <FadeInSection>
          <SuggestionStrip
            context={this.props.context}
            changeHistory={this.props.changeHistory}
          />
        </FadeInSection>
        <FadeInSection>
          <div className="page-img-footers centerized">
            <img id="galleryPreview" src={getImg("uploadfooterbanner.png")} />

            <div className="overlay-text">
              Got a project or artwork in mind?
              <br />
              Talk to us.
              <br />
              We support every step of the creative process!
              <br />
              <div
                className="black-button lander-quotes"
                onClick={() => {
                  this.props.changeHistory("/contact");
                }}
              >
                Contact us
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}
