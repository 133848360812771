import React, { useState, useRef } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

type Props = {
  question: string;
  answer: string;
};

export const Accordian = (props: Props) => {
  const [open, setOpen] = useState(false);
  const contentEl = useRef<HTMLDivElement>(null);

  return (
    <div className="accordian">
      <div
        className="accordian-q"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div>{props.question}</div>
        {open ? (
          <AiOutlineMinus size={"25px"} />
        ) : (
          <AiOutlinePlus size={"25px"} />
        )}
      </div>
      <div
        ref={contentEl}
        className="accordian-a"
        style={{
          height: `${
            open && contentEl.current
              ? contentEl.current.scrollHeight + "px"
              : "0px"
          }`,
        }}
      >
        <div>{props.answer}</div>
      </div>
    </div>
  );
};
