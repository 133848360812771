import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/AdminPanel/DashboardHeader";

import {
  calculateRange,
  sliceData,
} from "../../../Components/Utility/table-pagination";
import { FaTrash, FaEdit } from "react-icons/fa";
import AddBlog from "./AddBlog";
import {
  getAllBlogs,
  blogDetails,
  updateBlog,
} from "../../../APIs/API";

type Props = {
  context: any;
};

function Blog(props: Props) {
  const [blogList, setBlogList]: any[] = useState([]);
  const [search, setSearch] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getAllBlogs().then((res) => {
      if (res.data.success === true) {
        setPagination(calculateRange(res.data.data, 10));
        setBlogs(sliceData(res.data.data, page, 10));
        setBlogList(res.data.data);
      }
    });
  };

  // Search
  const __handleSearch = (event: any) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = blogList.filter((item: blogDetails) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );
      setBlogs(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setBlogs(sliceData(blogList, new_page, 10));
  };

  const initiateUpdateBlog = (item: blogDetails) => {
    props.context.setOverlay(
      true,
      "Update blog",
      <AddBlog
        context={props.context}
        data={item}
        refresh={() => refresh()}
      />
    );
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader
        context={props.context}
        onClick={() => {
          props.context.setOverlay(
            true,
            "Add new blog",
            <AddBlog context={props.context} refresh={() => refresh()} />
          );
        }}
        btnText="New blog"
      />

      <div className="dashboard-content-container styledScroll">
        <div className="dashboard-content-header">
          <h2>Blogs List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search page..."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>ID</th>
            <th>TITLE</th>
            <th>IMAGE</th>
            <th>DESCRIPTION</th>
            <th>META DATA</th>
            <th>STATUS</th>
            <th>EDIT/HIDE</th>
          </thead>

          {blogs.length !== 0 ? (
            <tbody>
              {blogs.map((blog: blogDetails, index) => (
                <tr key={index}>
                  <td>
                    <span>{blog.id}</span>
                  </td>
                  <td>
                    <span>{blog.title}</span>
                  </td>
                  <td>
                    <img
                      src={blog.image}
                      alt="refunded-icon"
                      className="dashboard-content-icon"
                    />
                  </td>
                  <td style={{ maxWidth: "400px",  }}>
                    <div  style={{fontSize: '8px', width:'400px', display: 'block'}} dangerouslySetInnerHTML={ {__html: blog.info}} />
                  </td> 
                  <td style={{ maxWidth: "200px" }}>
                    <div className="text-too-long">{blog.meta}</div>
                  </td> 
                  <td>
                    <span>{blog.status ? "Active" : "Hidden"}</span>
                  </td>
                  <td>
                    <div className="flexed just-around" style={{ gap: "10px" }}>
                      <FaEdit
                        color="#ddb253"
                        size="28px"
                        onClick={() => {
                          initiateUpdateBlog(blog);
                        }}
                      />
                      <FaTrash
                        color="#ddb253"
                        size="24px"
                        onClick={() => {
                          let temp = {
                            id: blog.id,
                            image: blog.image,
                            status: false,
                            info: blog.info,
                            title: blog.title,
                            meta: blog.meta
                          };
                          updateBlog(temp);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {blogs.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item: any, index: number) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
