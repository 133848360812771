import React, { Component } from "react";
import { getCoupon } from "../../APIs/API";

import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";
import SuggestionStrip from "../Gallery/SuggestionStrip";
import AddOrderModel from "./AddOrderModel";
import CartItem from "./CartItem";

type Props = {
  context: any;
  changeHistory: Function;
};

type State = {
  total: number[];
  discount: number;
};

class Cart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      total: [0, 0],
      discount: 0,
    };
  }

  componentDidMount(): void {
    this.refactorBill();
    this.props.context.updateContextCart();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getTotalItems = () => {
    let [x, y, z] = [0, 0, 0];
    Object.values(this.props.context.cart).map((e: any, i) => {
      x = x + e.quantity;
      y = y + e.quantity * e.price;
    });
    z = parseFloat(((y - y * (this.state.discount / 100)) * 0.18).toFixed(2));
    return [x, y, z];
  };

  refactorBill = () => {
    this.setState({
      total: this.getTotalItems(),
    });
  };

  render() {
    return (
      <div className="koha-cart">
        <div className="koha-cart-content">
          <div className="cart-items styledScroll">
            <CartItem
              context={this.props.context}
              refresh={this.refactorBill.bind(this)}
            />
          </div>
          <div className="cart-total">
            <h3>
              Subtotal: ({this.state.total[0]} item): ₹ {this.state.total[1]}
            </h3>
            <div className="coupon-code">
              Add coupon:
              <p>
                <input type="text" name="" id="couponInput" />
                <div
                  className="black-button"
                  onClick={() => {
                    let cid = document.getElementById(
                      "couponInput"
                    ) as HTMLInputElement;

                    getCoupon(cid.value)
                      .then((res) => {
                        if (res.data.data.status)
                          this.setState(
                            {
                              discount: res.data.data.discount,
                            },
                            () => {
                              this.refactorBill();
                            }
                          );
                        else {
                          this.props.context.setItem(
                            "toasterText",
                            "This coupon has expired."
                          );
                        }
                      })
                      .catch((e) => {
                        this.setState(
                          {
                            discount: 0,
                          },
                          () => {
                            this.refactorBill();
                          }
                        );
                      });
                  }}
                >
                  Apply
                </div>
              </p>
            </div>
            <p className="rates">
              <div>Discount:</div>{" "}
              <div>
                -{" "}
                {(this.state.total[1] * (this.state.discount / 100)).toFixed(2)}
              </div>
            </p>
            <p className="rates">
              <div>Shipping and handling:(Free shipping across India)</div>{" "}
              <div>{(0).toFixed(2)}</div>
            </p>
            <p className="rates">
              <div>Tax:</div>{" "}
              <div>
                {this.state.total[2]
                  ? this.state.total[2].toFixed(2)
                  : (0).toFixed(2)}
              </div>
            </p>
            <h3 className="rates">
              <div>
                Total:
                {/* ({this.state.data.length} item): */}
              </div>

              <div>
                ₹{" "}
                {(
                  this.state.total[1] +
                  this.state.total[2] -
                  this.state.total[1] * (this.state.discount / 100)
                ).toFixed(2)}
              </div>
            </h3>
            <div
              className={`black-button ${
                Object.keys(this.props.context.cart).length === 0
                  ? "unclickable uneventful blur"
                  : ""
              }`}
              onClick={() => {
                this.props.context.setOverlay(
                  true,
                  "Order Details",
                  <AddOrderModel
                    context={this.props.context}
                    price={this.state.total[1]}
                    discount={this.state.discount}
                  />
                );
              }}
            >
              Checkout
            </div>
          </div>
        </div>
        <FadeInSection>
          <SuggestionStrip
            context={this.props.context}
            changeHistory={this.props.changeHistory}
          />
        </FadeInSection>
        <FadeInSection>
          <div className="page-img-footers centerized">
            <img id="galleryPreview" src={getImg("uploadfooterbanner.png")} />

            <div className="overlay-text">
              Got a project or artwork in mind?
              <br />
              Talk to us.
              <br />
              We support every step of the creative process!
              <br />
              <div className="black-button lander-quotes">Contact us</div>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default Cart;
