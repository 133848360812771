import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/AdminPanel/DashboardHeader";

import all_orders from "../../../Constants/adminConstants";
import {
  calculateRange,
  sliceData,
} from "../../../Components/Utility/table-pagination";
import { FaTrash, FaEdit } from "react-icons/fa";
import AddProduct from "./AddProduct";
import {
  getAllProducts,
  itemDetailsType3,
  updateProduct,
} from "../../../APIs/API";

type Props = {
  context: any;
};

function Products(props: Props) {
  const [productList, setProductList]: any[] = useState([]);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getAllProducts().then((res) => {
      if (res.data.success === true) {
        setPagination(calculateRange(res.data.data, 10));
        setProducts(sliceData(res.data.data, page, 10));
        setProductList(res.data.data);
      }
    });
  };

  // Search
  const __handleSearch = (event: any) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = productList.filter((item: itemDetailsType3) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setProducts(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setProducts(sliceData(productList, new_page, 10));
  };

  const initiateUpdateProduct = (item: itemDetailsType3) => {
    props.context.setOverlay(
      true,
      "Update product",
      <AddProduct
        context={props.context}
        data={item}
        refresh={() => refresh()}
      />
    );
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader
        context={props.context}
        onClick={() => {
          props.context.setOverlay(
            true,
            "Add new product",
            <AddProduct context={props.context} refresh={() => refresh()} />
          );
        }}
        btnText="New product"
      />

      <div className="dashboard-content-container styledScroll">
        <div className="dashboard-content-header">
          <h2>Products List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search page..."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>ID</th>
            <th>NAME</th>
            <th>IMAGE</th>
            <th>DESCRIPTION</th>
            <th>SIZES</th>
            <th>STATUS</th>
            <th>EDIT/HIDE</th>
          </thead>

          {products.length !== 0 ? (
            <tbody>
              {products.map((product: itemDetailsType3, index) => (
                <tr key={index}>
                  <td>
                    <span>{product.itemid}</span>
                  </td>
                  <td>
                    <span>{product.name}</span>
                  </td>
                  <td>
                    <img
                      src={product.item_link}
                      alt="refunded-icon"
                      className="dashboard-content-icon"
                    />
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    <div className="text-too-long">{product.description}</div>
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    {Object.values(product.sizes) &&
                      Object.values(product.sizes).map((e: any, i: number) => {
                        return (
                          <div
                            key={i}
                            style={{ marginTop: i !== 0 ? "10px" : 0 }}
                          >
                            <img
                              src={e.image}
                              className="dashboard-content-avatar"
                              alt={e.size}
                              style={{ borderRadius: 0, objectFit: "contain" }}
                            />
                            <span>
                              {e.size}
                              <br />
                              {e.dimensions.x + " x " + e.dimensions.y}
                            </span>
                          </div>
                        );
                      })}
                  </td>

                  <td>
                    <span>{product.status ? "Active" : "Hidden"}</span>
                  </td>
                  <td>
                    <div className="flexed just-around" style={{ gap: "10px" }}>
                      <FaEdit
                        color="#ddb253"
                        size="28px"
                        onClick={() => {
                          initiateUpdateProduct(product);
                        }}
                      />
                      <FaTrash
                        color="#ddb253"
                        size="24px"
                        onClick={() => {
                          let temp = {
                            itemid: product.itemid,
                            item_link: product.item_link,
                            price: 0,
                            quantity: 0,
                            status: false,
                            sizes: {
                              ...product.sizes,
                            },
                            name: product.name,
                          };
                          updateProduct(temp);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {products.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item: any, index: number) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
