import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import SideBar from "./Components/AdminPanel/Sidebar";
import { FadeInSection } from "./Components/Functional/FadeInSection";
import OverlayBoxAdmin from "./Components/Functional/OverlayBoxAdmin";
import Toaster from "./Components/Functional/Toaster";
import sidebar_menu from "./Constants/sidebar-menu";

import "./DashApp.scss";
import Admins from "./Pages/AdminPanelPages/Admins/Admins";
import Coupons from "./Pages/AdminPanelPages/Coupons/Coupons";
import Dashboard from "./Pages/AdminPanelPages/Dashboard/Dashboard";
import Orders from "./Pages/AdminPanelPages/Orders/Orders";
import Products from "./Pages/AdminPanelPages/Products/Products";
import Users from "./Pages/AdminPanelPages/Users/Users";
import  Blog  from "./Pages/AdminPanelPages/Blog/Blog";
import Login from "./Pages/Landing/Login";

function DashApp(props: any) {
  const navigate = useNavigate();

  const changeHistory = (val: string) => {
    navigate(val);
  };
  return (
    <div className="dashboard-container koha-main">
      {props.userName ? (
        <SideBar
          menu={sidebar_menu}
          logout={() => {
            props.logout();
            changeHistory("/admin-panel");
          }}
        />
      ) : null}

      <div className="dashboard-body styledScroll">
        <Routes>
          <Route
            path="/admin-panel"
            element={
              props.userName ? (
                <Orders context={props} />
              ) : (
                <div style={{ minHeight: "100vh" }} className="centerized">
                  <FadeInSection>
                    <Login login={props.login} toaster={props.toasterText} />
                  </FadeInSection>
                </div>
              )
            }
          />
          <Route
            path="/admin-panel/orders"
            element={<Orders context={props} />}
          />
          <Route
            path="/admin-panel/products"
            element={<Products context={props} />}
          />
          <Route
            path="/admin-panel/users"
            element={<Users context={props} />}
          />
          <Route
            path="/admin-panel/coupons"
            element={<Coupons context={props} />}
          />
          <Route
            path="/admin-panel/admins"
            element={<Admins context={props} />}
          />

<Route
            path="/admin-panel/blog"
            element={<Blog context={props} />}
          />
        </Routes>

        {props.overlayItems && props.overlayItems.show ? (
          <OverlayBoxAdmin
            header={props.overlayItems.title}
            body={props.overlayItems.body}
            buttons={props.overlayItems.buttons}
            buttonCentered={props.overlayItems.buttonCentered}
            exit={() => props.setOverlay(false)}
          />
        ) : null}
      </div>
      <Toaster
        text={props.toasterText}
        clearText={() => props.setItem("toasterText", "")}
      />
    </div>
  );
}

export default DashApp;
