import React, { lazy, Suspense, useState } from "react";
import "./commons.scss";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { Loader } from "./Loader";
import { FadeInSection } from "./Components/Functional/FadeInSection";
import ResetPass from "./Pages/Landing/ResetPass";
import { AboutUs } from "./Pages/StaticPages/AboutUs";
import { HowItWorks } from "./Pages/StaticPages/HowItWorks";
import { FAQ } from "./Pages/StaticPages/FAQ";
import { ContactUs } from "./Pages/StaticPages/ContactUs";
import { Shipping } from "./Pages/StaticPages/Shipping";
import { Privacy } from "./Pages/StaticPages/Privacy";
import { Returns } from "./Pages/StaticPages/Returns";
import { OrderStatus } from "./Pages/StaticPages/OrderStatus";
import Toaster from "./Components/Functional/Toaster";
import Profile from "./Pages/Profile/Profile";
import IRSEntry from "./Pages/Customization/IRSEntry";
import Cart from "./Pages/Cart/Cart";
import Gallery from "./Pages/Gallery/Gallery";
import OverlayBox from "./Components/Functional/OverlayBox";
import ItemDetails from "./Pages/Gallery/ItemDetails";
import {Blog} from "./Pages/Blog/Blog";
const LandingPage = lazy(() => import("./Pages/Landing/Landing"));
const Gateway = lazy(() => import("./Pages/Landing/Gateway"));
const Login = lazy(() => import("./Pages/Landing/Login"));
const Signup = lazy(() => import("./Pages/Landing/Signup"));
const ForgotPass = lazy(() => import("./Pages/Landing/ForgotPass"));

function App(props: any) {
  const [test, setTest] = useState(true);

  const navigate = useNavigate();

  const changeHistory = (val: string) => {
    navigate(val);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="koha-main">
      {test && props.userName == "" && window.location.pathname == "/" ? (
        // window.location.pathname.indexOf("resetpass") == -1 &&
        // window.location.pathname.indexOf("order") == -1 &&
        // window.location.pathname.indexOf("create") == -1 &&
        // window.location.pathname.indexOf("profile") == -1 &&
        // window.location.pathname.indexOf("itemdetails") == -1
        <Suspense fallback={<Loader fullScreen />}>
          <Gateway
            onClick={() => {
              setTest(false);
              changeHistory("/");
            }}
          />
        </Suspense>
      ) : (
        <Layout context={props} changeHistory={changeHistory}>
          <Suspense
            fallback={
              <div style={{ width: "100vw" }}>
                <Loader />
              </div>
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <LandingPage context={props} changeHistory={changeHistory} />
                }
              />

              {/* <Route path="/orderbook" element={<OrderBook {...props} />} /> */}

              <Route
                path="/resetpass"
                element={
                  <ResetPass context={props} changeHistory={changeHistory} />
                }
              />

              <Route
                path="/signup"
                element={
                  <Signup context={props} changeHistory={changeHistory} />
                }
              />

              <Route
                path="/forgotpass"
                element={
                  <div style={{ minHeight: "43.5vh" }}>
                    <ForgotPass context={props} changeHistory={changeHistory} />
                  </div>
                }
              />

              <Route path="/blog" element={<Blog />} />

              <Route path="/aboutus" element={<AboutUs />} />

              <Route path="/howitworks" element={<HowItWorks />} />

              <Route path="/faq" element={<FAQ />} />

              <Route path="/contact" element={<ContactUs context={props} />} />

              <Route path="/shipping" element={<Shipping />} />

              <Route path="/privacy" element={<Privacy />} />

              <Route path="/return" element={<Returns />} />

              <Route
                path={"/order/failure"}
                element={<OrderStatus orderStatus={false} context={props} />}
              />

              <Route
                path={"/order/success"}
                element={<OrderStatus orderStatus={true} context={props} />}
              />

              <Route
                path="/create"
                element={
                  <IRSEntry context={props} changeHistory={changeHistory} />
                }
              />

              <Route
                path="/profile"
                element={
                  props.userName ? (
                    <Profile context={props} changeHistory={changeHistory} />
                  ) : (
                    <div>
                      <FadeInSection>
                        <Login
                          login={props.login}
                          toaster={props.toasterText}
                        />
                      </FadeInSection>
                    </div>
                  )
                }
              />

              <Route
                path="/gallery"
                element={
                  <Gallery context={props} changeHistory={changeHistory} />
                }
              />

              <Route
                path="/itemdetails"
                element={
                  <ItemDetails context={props} changeHistory={changeHistory} />
                }
              />

              <Route
                path="/cart"
                element={<Cart context={props} changeHistory={changeHistory} />}
              />

              <Route
                path="*"
                element={
                  // props.userName ? (
                  <LandingPage {...props} />
                  // ) : (
                  //   <div style={{ minHeight: "50vh" }}>
                  //     <FadeInSection>
                  //       <Login login={props.login} />
                  //     </FadeInSection>
                  //   </div>
                  // )
                }
              />
            </Routes>
          </Suspense>
          <Toaster
            text={props.toasterText}
            clearText={() =>
              props.setItem ? props.setItem("toasterText", "") : null
            }
          />
          {props.overlayItems && props.overlayItems.show ? (
            <OverlayBox
              header={props.overlayItems.title}
              body={props.overlayItems.body}
              buttons={props.overlayItems.buttons}
              buttonCentered={props.overlayItems.buttonCentered}
              exit={() => props.setOverlay(false)}
            />
          ) : null}
        </Layout>
      )}
    </div>
  );
}

export default App;
