import React, { useEffect } from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { updateCart } from "../../APIs/API";

type Props = {
  orderStatus: boolean;
  context: any;
};

export const OrderStatus = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (props.orderStatus === true) {
      updateCart(props.context.userName, {})
        .then((res) => {
          props.context.setItem("cart", res.data.data);
          props.context.setItem("cartCount", res.data.data.length);
          props.context.setItem("toasterText", "Cart has been updated.");
        })
        .catch((e) => {
          props.context.setItem(
            "toasterText",
            "Unable to update cart. Please try again later."
          );
        });
    }
  }, [props.orderStatus]);

  return (
    <div className="koha-static">
      <div className="para-header">Order Status</div>
      {window.location.pathname.indexOf("success") !== -1 ? (
        <div className="team-member centerized flexed columned">
          <AiOutlineCheckCircle color="green" size={"100px"} />
          <div style={{ textAlign: "center" }}>
            Your order has been confirmed. <br />
            <br />
            Order status will be updated to you through your registered email.
          </div>
        </div>
      ) : (
        <div className="team-member centerized flexed columned">
          <AiOutlineCloseCircle color="red" size={"100px"} />
          <div style={{ textAlign: "center" }}>
            Your order transaction has failed. <br />
            <br />
            Please try again. Any payment made will be refunded in a short
            while.
          </div>
        </div>
      )}
    </div>
  );
};
