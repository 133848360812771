import React, { useState, useEffect } from "react";
import {
  calculateRange,
  sliceData,
} from "../../Components/Utility/table-pagination";
import {
  FaFileDownload,
  FaPlusCircle,
  FaMinusCircle,
  FaTrash,
} from "react-icons/fa";
import { updateCart, legofy } from "../../APIs/API";

type Props = {
  context: any;
  refresh: Function;
};

function CartItem(props: Props) {
  const [orders, setOrders] = useState(Object.values(props.context.cart));
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    setPagination(calculateRange(Object.values(props.context.cart), 10));
    setOrders(sliceData(Object.values(props.context.cart), page, 10));
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setOrders(sliceData(Object.values(props.context.cart), new_page, 10));
  };

  const changeCartQuant = (id: number, change: boolean) => {
    setLoading(true);
    let currentCart = props.context.cart;

    currentCart[id].quantity = change
      ? currentCart[id].quantity + 1
      : currentCart[id].quantity - 1;
    if (props.context.userName === "") {
      props.context.setItem("cart", currentCart);
      props.context.setItem("toasterText", "Cart has been updated.");
      setLoading(false);
      props.refresh();
    } else {
      updateCart(props.context.userName, currentCart)
        .then((res) => {
          props.context.setItem("cart", res.data.data);
          props.context.setItem("toasterText", "Cart has been updated.");
          refresh();
          props.refresh();
          setLoading(false);
        })
        .catch((e) => {
          props.context.setItem(
            "toasterText",
            "Unable to update cart. Please try again later."
          );
        });
    }
  };

  const deleteItemFromCart = (id: number) => {
    setLoading(true);
    let currentCart = props.context.cart;
    delete currentCart[id];
    if (props.context.userName === "") {
      props.context.setItem("cart", currentCart);
      props.context.setItem("cartCount", props.context.cartCount - 1);
      props.context.setItem("toasterText", "Cart has been updated.");
      setLoading(false);
      props.refresh();
      refresh();
    } else {
      updateCart(props.context.userName, currentCart)
        .then((res) => {
          props.context.setItem("cart", res.data.data);
          props.context.setItem("cartCount", props.context.cartCount - 1);
          props.context.setItem("toasterText", "Cart has been updated.");
          setLoading(false);
          refresh();
          props.refresh();
        })
        .catch((e) => {
          props.context.setItem(
            "toasterText",
            "Unable to update cart. Please try again later."
          );
        });
    }
  };

  //Genreate irs outputs
  const generateCsv = (name: string, config: any) => {
    config.finalize = true;
    setLoading(true);
    legofy(config).finally(() => {
      setLoading(false);
      // setTimeout(() => {
      let x = document.getElementById(name) as HTMLAnchorElement;
      if (x) {
        x.click();
      }
      // }, 5000);
    });
  };

  return (
    <div>
      <table>
        <thead>
          <th>ID</th>
          <th>NAME</th>
          <th>PRODUCT</th>
          <th>SIZE</th>
          <th>QUANTITY</th>
          <th>PRICE</th>
          <th>REMOVE{props.context.admin ? "/DOWNLOAD" : ""}</th>
        </thead>

        {orders.length !== 0 ? (
          <tbody>
            {orders.map((order: any, index: number) => (
              <tr key={index}>
                <td style={{ minWidth: "20px" }}>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <div
                    className="text-too-long"
                    style={{
                      maxWidth: "90%",
                    }}
                  >
                    {order.name}
                  </div>
                </td>
                <td>
                  <div className="flexed just-centered">
                    <img
                      src={order.image}
                      style={{ borderRadius: 0, objectFit: "contain" }}
                      className="dashboard-content-avatar"
                      alt={""}
                    />
                  </div>
                </td>
                <td>
                  <span>
                    {order.dimesions.x}X{order.dimesions.y}
                  </span>
                </td>
                <td>
                  <div className="flexed just-between">
                    <FaMinusCircle
                      color={"#ddb253"}
                      size={"14px"}
                      className={`${
                        loading || order.quantity <= 1
                          ? "unclickable uneventful blur"
                          : "clickable"
                      } `}
                      onClick={() => {
                        if (order.quantity > 1)
                          changeCartQuant(order.id, false);
                      }}
                    />
                    <div className="centerized">{order.quantity}</div>
                    <FaPlusCircle
                      color={"#ddb253"}
                      size={"14px"}
                      className={`${
                        loading ? "unclickable uneventful blur" : "clickable"
                      } `}
                      onClick={() => {
                        changeCartQuant(order.id, true);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <span>₹ {order.price * order.quantity}</span>
                </td>
                <td style={{ minWidth: "30px" }}>
                  <div
                    className={`${
                      loading ? "unclickable uneventful blur" : "clickable"
                    } flexed ${
                      props.context.admin ? "just-around" : "just-centered"
                    }`}
                  >
                    <FaTrash
                      color="#ddb253"
                      size="24px"
                      onClick={() => {
                        deleteItemFromCart(order.id);
                      }}
                    />
                    {order.irsConfig && props.context.admin ? (
                      <span>
                        <FaFileDownload
                          className={`${
                            loading
                              ? "unclickable uneventful blur"
                              : "clickable"
                          }`}
                          onClick={() => {
                            generateCsv(order.name + "_dl", order.irsConfig);
                          }}
                          color="#ddb263"
                          size={"26px"}
                        />
                        <a
                          id={order.name + "_dl"}
                          href={`${order.irsConfig.imgpath}_zip`}
                          download
                          style={{ visibility: "hidden" }}
                        />
                      </span>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>{" "}
      {orders.length !== 0 ? (
        <div className="dashboard-content-footer">
          {pagination.map((item: any, index: number) => (
            <span
              key={index}
              className={item === page ? "active-pagination" : "pagination"}
              onClick={() => __handleChangePage(item)}
            >
              {item}
            </span>
          ))}
        </div>
      ) : (
        <div className="dashboard-content-footer" style={{ height: "40px" }}>
          <span className="empty-table">No data</span>
        </div>
      )}
    </div>
  );
}

export default CartItem;
