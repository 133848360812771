import React, { Component } from "react";
import { adminLogin } from "../../APIs/API";

const Context = React.createContext();

class KohaAdminContext extends Component {
  constructor(props) {
    super(props);

    const initialState = {
      userName: "",
      toasterText: "",
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },
    };

    this.state = {
      userName: "",
      toasterText: "",
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },

      //functions
      setItem: (key, val) => {
        this.setState({
          [key]: val,
        });
        sessionStorage.setItem("currentState", JSON.stringify(this.state));
      },
      login: (uName, pass, callback) => {
        if (uName.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) && pass !== "") {
          adminLogin(false, uName, pass)
            .then((res) => {
              callback();
              if (res.data.success === true) {
                this.setState({
                  userName: uName,
                  toasterText: res.data.message,
                });

                sessionStorage.setItem(
                  "currentState",
                  JSON.stringify(this.state)
                );
              } else {
                this.setState({
                  toasterText: "Login failed. Please try again later.",
                });
              }
            })
            .catch((res) => {
              callback();
              this.setState({ toasterText: res.response.data.errors });
            });
        } else {
          this.setState({
            toasterText: "Enter valid email/password",
          });
        }
      },
      logout: () => {
        this.setState({
          ...initialState,
        });
      },
      setOverlay: (
        show = false,
        title = "",
        body = null,
        buttons = [],
        buttonCentered = false
      ) => {
        if (show === false) {
          this.setState({
            overlayItems: { title: "", body: null, buttons: [], show: false },
          });
        } else {
          this.setState({
            overlayItems: {
              title: title,
              body: body,
              buttons: buttons,
              show: true,
              buttonCentered: buttonCentered,
            },
          });
        }
      },
    };
  }

  componentDidMount() {
    let temp = sessionStorage.getItem("currentState");
    if (temp === null) {
      this.firstEntry();
    }
    this.consequentEntries();
  }

  firstEntry = () => {
    this.state.logout();
    sessionStorage.setItem("currentState", JSON.stringify(this.state));
  };

  consequentEntries = () => {
    let temp = JSON.parse(sessionStorage.getItem("currentState"));
    this.setState({
      ...temp,
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },
    });
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

const AdminContextHOC = (SomeComponent, props) => {
  return (
    <Context.Consumer>
      {(val) => <SomeComponent {...val} {...props} />}
    </Context.Consumer>
  );
};

export { KohaAdminContext, AdminContextHOC };
