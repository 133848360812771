import React, { Component } from "react";
import Select from "react-select";
import {
  FaRulerCombined,
  FaCubes,
  FaRupeeSign,
  FaHeart,
  FaPlusCircle,
  FaQuestion,
  FaMinusCircle,
  FaQuestionCircle,
} from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { itemDetailsType3, itemSizeType, updateCart } from "../../APIs/API";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import SuggestionStrip from "./SuggestionStrip";
import AskAQuestion from "./AskAQuestion";

type Props = {
  context: any;
  changeHistory: Function;
};

type State = {
  itemDetails: itemDetailsType3;
  imageToShow: string;
  selectedSize: { label: string; value: itemSizeType };
  quantity: number;
  sizeList: { label: string; value: itemSizeType }[];
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: "1px solid #ddb253",
    color: "#ddb253 !important",
    padding: 10,
    backgroundColor: "#000 !important",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#000",
    border: "1px solid #ddb253 !important",
    color: "#ddb253 !important",
    width: "100%",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#ddb253",
    backgroundColor: "#000",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "#ddb253",
    backgroundColor: "#000",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    color: "#ddb253",
    backgroundColor: "#ddb253",
  }),

  singleValue: (provided: any, state: any) => {
    const color = "#ddb253 !important";
    const width = "100% !important";

    return { ...provided, color, width };
  },
};

export default class ItemDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      itemDetails: {
        name: "",
        itemid: 0,
        item_link: "",
        status: true,
        sizes: [],
        quantity: 0,
        price: 0,
        description: "",
      },
      imageToShow: "",
      selectedSize: {
        label: "",
        value: {
          image: "",
          size: "",
          dimensions: { x: 0, y: 0 },
        },
      },
      quantity: 1,
      sizeList: [],
    };
  }

  componentDidMount() {
    this.setItem(this.props.context.itemDetails);
  }

  setItem = (item: itemDetailsType3) => {
    let temp = Object.values(item.sizes).map((e: any, i: number) => {
      return { label: e.size, value: e };
    });

    this.setState({
      itemDetails: item,
      imageToShow: temp[0].value.image,
      sizeList: temp,
      selectedSize: temp[0],
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  getCartId = () => {
    let i = "1";
    while (
      Object.keys(this.props.context.cart).includes(i) &&
      parseInt(i) < this.props.context.cartCount + 1
    ) {
      i = JSON.stringify(parseInt(i) + 1);
    }
    return parseInt(i);
  };

  addItemToCart() {
    let currentCart = this.props.context.cart;
    let newItem = {
      id: this.getCartId(),
      name: this.state.itemDetails.name,
      image: this.state.imageToShow,
      dimesions: { ...this.state.selectedSize.value.dimensions },
      price:
        this.state.selectedSize.value.dimensions.x *
        this.state.selectedSize.value.dimensions.y *
        1900,
      quantity: this.state.quantity,
    };
    currentCart[this.getCartId()] = newItem;
    if (this.props.context.userName === "") {
      this.props.context.setItem("cart", currentCart);
      this.props.context.setItem("cartCount", Object.keys(currentCart).length);
      this.props.context.setItem("toasterText", "Item has been added to cart.");
    } else {
      updateCart(this.props.context.userName, currentCart)
        .then((res) => {
          this.props.context.setItem("cart", res.data.data);
          this.props.context.setItem(
            "cartCount",
            Object.keys(currentCart).length
          );
          this.props.context.setItem(
            "toasterText",
            "Item has been added to cart."
          );
        })
        .catch((e) => {
          this.props.context.setItem(
            "toasterText",
            "Unable to add item to cart. Please try again later."
          );
        });
    }
  }

  render() {
    return (
      <>
        <div className="item-detail">
          <img src={this.state.imageToShow} alt="" className="item-image" />
          <div className="item-detail-content">
            <div className="item-name">{this.state.itemDetails.name}</div>
            <div className="item-desc">
              {this.state.itemDetails.description}
            </div>
            <div className="size-select">
              <Select
                options={this.state.sizeList}
                value={this.state.selectedSize}
                styles={customStyles}
                onChange={(val) => {
                  if (val)
                    this.setState({
                      selectedSize: val,
                      imageToShow: val.value.image,
                    });
                }}
              />
            </div>
            <div className="IRS-details-strip">
              <div className="IRS-detail-snip">
                <FaRulerCombined color="#ddb253" size={"12px"} />
                <div>
                  Size:{" "}
                  {Math.round(
                    this.state.selectedSize.value.dimensions.x * 25.6
                  )}{" "}
                  cm X{" "}
                  {Math.round(
                    this.state.selectedSize.value.dimensions.y * 25.6
                  )}{" "}
                  cm
                </div>
              </div>
              <div className="IRS-detail-snip">
                <FaRupeeSign color="#ddb253" size={"12px"} />
                Price: ₹{" "}
                {this.state.selectedSize.value.dimensions.x *
                  this.state.selectedSize.value.dimensions.y *
                  1900}
              </div>
              <div className="IRS-detail-snip">
                <FaCubes color="#ddb253" size={"12px"} />
                <div>
                  Pieces:{" "}
                  {this.state.selectedSize.value.dimensions.x *
                    this.state.selectedSize.value.dimensions.y *
                    1024}{" "}
                  bricks
                </div>
              </div>
            </div>

            <div className="item-options">
              <div className="quantity">
                Quantity:
                <FaMinusCircle
                  color={"#ddb253"}
                  size={"14px"}
                  className="clickable"
                  onClick={() => {
                    this.setState({
                      quantity:
                        this.state.quantity > 1 ? this.state.quantity - 1 : 1,
                    });
                  }}
                />
                <div className="centerized">{this.state.quantity}</div>
                <FaPlusCircle
                  color={"#ddb253"}
                  size={"14px"}
                  className="clickable"
                  onClick={() => {
                    this.setState({
                      quantity: this.state.quantity + 1,
                    });
                  }}
                />
              </div>
              {/* <div className="black-button">
                <FaHeart color="white" size="16px" />
                &nbsp;Add to wishlist
              </div> */}
              <div
                className="black-button"
                onClick={() => {
                  if (this.state.selectedSize.value.size) this.addItemToCart();
                  else
                    this.props.context.setItem(
                      "toasterText",
                      "Please select a size."
                    );
                }}
              >
                <AiOutlineShoppingCart color="white" size="20px" />
                &nbsp;Add to cart
              </div>
            </div>

            <div className="item-feedback">
              <p>
                Got any ideas for products? We would love to hear your thoughts.
                <div
                  className="black-button"
                  style={{ margin: "10px auto" }}
                  onClick={() => {
                    this.props.context.setOverlay(
                      true,
                      "Ask a question",
                      <AskAQuestion context={this.props.context} />
                    );
                  }}
                >
                  <FaQuestionCircle color="white" size="20px" />
                  &nbsp;Ask a question
                </div>
              </p>
            </div>
          </div>
        </div>

        <FadeInSection>
          <SuggestionStrip
            context={this.props.context}
            changeHistory={this.props.changeHistory}
            reloadItem={this.setItem}
          />
        </FadeInSection>
      </>
    );
  }
}
