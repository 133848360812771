import React, { useState } from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";

import { AiFillInstagram, AiFillMail, AiFillPhone } from "react-icons/ai";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";
import { feedback_query } from "../../APIs/API";

type Props = { context: any };

export const ContactUs = (props: Props) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const checkForm = () => {
    if (
      name !== "" &&
      mail !== "" &&
      phone !== "" &&
      time !== "" &&
      subject !== "" &&
      message !== ""
    )
      return true;
    else return false;
  };

  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="page-img-headers koha-contactus">
          <img src={getImg("contactusbannerimage.jpg")} />
          <div className="overlay-text ">
            <div className="para-header">Lets have a talk</div>
            <div style={{ color: "white", fontStyle: "italic" }}>
              We always love getting in touch with our customers, so please feel
              free to drop us a line. We respond to every message and every
              message is read and appreciated! We are a small company so any
              support would mean the world to us! A "like" on Facebook, a "pin"
              on Pinterest or just sending our link to a friend would be super
              amazing! If there is anything we can do in return, drop us an
              email!
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="contact-us-body">
          <form className="contact-form" autoComplete="off">
            <div className="flexed columned ali-centered">
              <h2>Any Queries or Feedback?</h2>
              <p>Let us know.</p>
            </div>
            <input
              placeholder="Name"
              autoComplete="off"
              type="text"
              value={name}
              onChange={(ev) => setName(ev.target.value)}
              required
            />

            <input
              placeholder="E-Mail"
              autoComplete="off"
              type="text"
              value={mail}
              onChange={(ev) => setMail(ev.target.value)}
              required
            />

            <input
              placeholder="Phone number"
              autoComplete="off"
              type="number"
              value={phone}
              onChange={(ev) => setPhone(ev.target.value)}
              required
            />

            <input
              placeholder="Time to contact"
              autoComplete="off"
              type="text"
              value={time}
              onChange={(ev) => setTime(ev.target.value)}
              required
            />

            <input
              placeholder="Subject"
              autoComplete="off"
              type="text"
              value={subject}
              onChange={(ev) => setSubject(ev.target.value)}
              required
            />

            <textarea
              placeholder="Message"
              autoComplete="off"
              value={message}
              onChange={(ev) => setMessage(ev.target.value)}
              required
            />

            <div
              className={`black-button ${
                checkForm() ? "" : "unclickable uneventful blur"
              }`}
              onClick={() => {
                feedback_query(name, mail, phone, time, subject, message).then(
                  (res) => {
                    props.context.setOverlay();
                    props.context.setItem(
                      "toasterText",
                      "Your query has been sent."
                    );
                  }
                );
              }}
            >
              Send Message
            </div>
          </form>
          <div className="contact-socials ">
            <div className="each-contact-social">
              <AiFillMail color="#ddb253" size={"100px"} />
              <b>Email Us</b>
              <div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText("info@thekoha.com");
                    props.context.setItem("toasterText", "copied to clipboard");
                  }}
                >
                  info@thekoha.com
                </div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText("support@thekoha.com");
                    props.context.setItem("toasterText", "copied to clipboard");
                  }}
                >
                  support@thekoha.com
                </div>
              </div>
            </div>

            <div
              className="each-contact-social"
              onClick={() => {
                navigator.clipboard.writeText("+91-9916299016");
                props.context.setItem("toasterText", "copied to clipboard");
              }}
            >
              <AiFillPhone color="#ddb253" size={"100px"} />
              <b>Call Us</b>
              <div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText("+91-9916299016");
                    props.context.setItem("toasterText", "copied to clipboard");
                  }}
                >
                  +91-9916299016
                </div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText("+91-7676869730");
                    props.context.setItem("toasterText", "copied to clipboard");
                  }}
                >
                  +91-7676869730
                </div>
              </div>
            </div>

            <a
              className="each-contact-social"
              href="https://www.youtube.com/channel/UCSCe4fur5PQ_4gsJ-0N4qLg/featured"
            >
              <FaYoutube color="#ddb253" size={"100px"} />
              <b>
                Subscribe to our
                <br /> channel
              </b>
              <div>Koha</div>
            </a>

            {/* <div className="each-contact-social">
              <AiFillInstagram color="#ddb253" size={"100px"} />
              <b>Follow us</b>
              <div>@KOHA</div>
            </div> */}

            <a
              className="each-contact-social"
              href="https://www.facebook.com/profile.php?id=100084668861156&sk=about_contact_and_basic_info"
            >
              <FaFacebookF color="#ddb253" size={"100px"} />
              <br />
              <b>Like us</b>
              <br />
            </a>

            <a
              className="each-contact-social"
              href="https://in.pinterest.com/0e4omdk5j63box81g9fu6ztmdeequk/"
            >
              <FaPinterest color="#ddb253" size={"100px"} />
              <br />
              <b>Pin us</b>
              <br />
            </a>

            <a
              className="each-contact-social"
              href="https://www.linkedin.com/company/koha-decor/?viewAsMember=true"
            >
              <FaLinkedin color="#ddb253" size={"100px"} />
              <br />
              <b>Follow us</b>
              <br />
            </a>

            {/* <div className="each-contact-social">
              <FaTwitter color="#ddb253" size={"100px"} />
              <b>Tweet us</b>
              <div>@Koha - Twitter</div>
            </div> */}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="koha-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7963337.876865135!2d73.2457159285747!3d12.949532895085252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150cf8cdad93%3A0x2813e62d51d11ceb!2sKOHA%20DECOR!5e0!3m2!1sen!2sin!4v1661885477154!5m2!1sen!2sin"
            loading="lazy"
          />
        </div>
      </FadeInSection>
    </div>
  );
};
