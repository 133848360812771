import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import DashApp from "./DashApp";
import _ from "lodash";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ContextHOC, KohaContext } from "./Components/Utility/Context";
import {
  AdminContextHOC,
  KohaAdminContext,
} from "./Components/Utility/AdminContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {/* {console.log(window.location.pathname)} */}
    {window.location.pathname.indexOf("admin-panel") === -1 ? (
      <KohaContext>{ContextHOC(App)}</KohaContext>
    ) : (
      <KohaAdminContext>{AdminContextHOC(DashApp)}</KohaAdminContext>
    )}
  </BrowserRouter>
);
