import React from "react";

type Props = {
  children: any;
};

export const FadeInSection = (props: Props) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef: any = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    const fadeOut = () => {
      if (domRef.current)
        if (
          window.innerHeight - domRef.current.getBoundingClientRect()?.y <=
          50
        ) {
          setVisible(false);
          window.removeEventListener("scroll", () => {
            fadeOut();
          });
        } else {
          setVisible(true);
        }
    };

    if (domRef.current) {
      if (
        window.innerHeight - domRef.current.getBoundingClientRect()?.y <=
        50
      ) {
        window.addEventListener("scroll", () => {
          fadeOut();
        });
      } else {
        setVisible(true);
      }
    }
    observer.observe(domRef.current);
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
};
