import React, { useState, useEffect } from "react";
import all_orders from "../../Constants/adminConstants";
import {
  calculateRange,
  sliceData,
} from "../../Components/Utility/table-pagination";
import { getUserOrders } from "../../APIs/API";

type Props = {
  context: any;
};

function MyOrders(props: Props) {
  const [orderList, setOrderList]: any[] = useState([]);
  const [orders, setOrders]: any[] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getUserOrders(props.context.userName).then((res) => {
      setPagination(calculateRange(Object.values(res.data.data).reverse(), 5));
      setOrderList(Object.values(res.data.data).reverse());
      setOrders(sliceData(Object.values(res.data.data).reverse(), page, 5));
    });
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setOrders(sliceData(orderList, new_page, 5));
  };

  return (
    <div>
      <table>
        <thead>
          <th>ORDER ID</th>
          <th>ORDER ITEMS</th>
          <th>TOTAL AMOUNT</th>
          <th>DELIVERY ADDRESS</th>
          <th>STATUS</th>
        </thead>

        {orders.length !== 0 ? (
          <tbody>
            {orders.map((order: any, index: number) => (
              <tr key={index}>
                <td>
                  <span>{order.bookingid}</span>
                </td>
                <td>
                  <span>
                    {order.items &&
                      Object.values(order.items).map((e: any, i: number) => {
                        return (
                          <div
                            className="each-order-item"
                            style={{ marginTop: i !== 0 ? "10px" : "0px" }}
                          >
                            <img src={e.image} alt="" />
                            <span>
                              {e.name}
                              <br />
                              {e.dimesions.x + " X " + e.dimesions.y}
                              <br />₹ {e.price}
                              <br />
                              {e.quantity} No(s)
                            </span>
                          </div>
                        );
                      })}
                  </span>
                </td>
                <td>
                  <span>₹ {order.price}</span>
                </td>
                <td>
                  <span>{`${order.apartment}, ${order.address}, ${order.city}, ${order.state}, ${order.country}-${order.pincode}`}</span>
                </td>
                <td>
                  <span>{order.status || "Pending"}</span>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>{" "}
      {orders.length !== 0 ? (
        <div className="dashboard-content-footer">
          {pagination.map((item: any, index: number) => (
            <span
              key={index}
              className={item === page ? "active-pagination" : "pagination"}
              onClick={() => __handleChangePage(item)}
            >
              {item}
            </span>
          ))}
        </div>
      ) : (
        <div className="dashboard-content-footer">
          <span className="empty-table">No data</span>
        </div>
      )}
    </div>
  );
}

export default MyOrders;
