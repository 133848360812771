import * as React from "react";
import { BiUserPlus } from "react-icons/bi";
import { changePass, errorHandler } from "../../APIs/API";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

interface Props {
  context: any;
}

interface State {
  pass: string;
  newPass: string;
  conPass: string;
}

class ChangePass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pass: "",
      newPass: "",
      conPass: "",
    };
  }

  checkForm = () => {
    if (
      this.state.pass !== "" &&
      this.state.newPass !== "" &&
      this.state.conPass !== ""
    )
      return true;
    else return false;
  };

  render() {
    return (
      <FadeInSection>
        <div className="koha-login">
          <div className="kl-header">Edit account</div>
          <form className="kl-body" autoComplete="off">
            <div>Old password</div>
            <input
              autoComplete="off"
              type="password"
              value={this.state.pass}
              onChange={(ev) =>
                this.setState({
                  pass: ev.target.value,
                })
              }
            />

            <div>New password</div>
            <input
              autoComplete="off"
              type="password"
              value={this.state.newPass}
              onChange={(ev) =>
                this.setState({
                  newPass: ev.target.value,
                })
              }
            />

            <div>Confirm new password
            {this.state.newPass !== this.state.conPass ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  &nbsp;(passwords do not match)
                </span>
              ) : null}
            </div>
            <input
              autoComplete="off"
              type={"password"}
              value={this.state.conPass}
              onChange={(ev) =>
                this.setState({
                  conPass: ev.target.value,
                })
              }
            />

            <div
              onClick={() => {
                changePass(false,this.props.context.userName,this.state.pass,this.state.conPass).then((res:any)=>{
                  console.log(res)
                  if(res.status===201){
                    this.props.context.setItem('toasterText',"Password changed.")
                  }else{
                    this.props.context.setItem('toasterText',"Something went wrong, please try again later.")
                  }
                }).catch((err:any)=>{
                  errorHandler(err,this.props.context.setItem('toasterText',"Something went wrong, please try again later."));
                })
              }}
              className={`login-but ${
                this.checkForm() ? "clickable" : "unclickable  uneventful blur"
              }`}
            >
              <BiUserPlus size="20px" />
              <span>Update</span>
            </div>
          </form>
        </div>
      </FadeInSection>
    );
  }
}

export default ChangePass;
