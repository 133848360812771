export const pageNames: Object = {
  "/signup": " / Sign up",
  "/forgotpass": " / Forgot password",
  "/resetpass": " / Reset password",
  "/aboutus": " / About us",
  "/howitworks": " / How it works",
  "/gallery": " / Gallery",
  "/accessories": " / Accessories",
  "/faq": " / FAQ",
  "/contact": " / Contact",
  "/cart": " / My cart",
  "/profile": " / My account",
  "/create": " / Customization",
  "/shipping": " / Shipping Information",
  "/privacy": " / Privacy Policy",
  "/return": " / Return Policy",
  "/order": " / Order Status",
  "/itemdetails": " / Item Details",
  "/blog": " / Blog",
};

export const IRSColors: [string, any][] = [
  ["244, 244, 244", '0'],
  ["208, 206, 201", '0'],
  ["178, 180, 178", '0'],
  ["140, 138, 136", '0'],
  ["100, 100, 100", '0'],
  ["22, 22, 22", '1'],
  ["0, 187, 220", '0'],
  ["175, 205, 215", '0'],
  ["139, 190, 232", '0'],
  ["126, 147, 167", '0'],
  ["62, 135, 203", '1'],
  ["0, 94, 184", '1'],
  ["51, 63, 72", '1'],
  ["0, 53, 80", '1'],
  ["190, 202, 234", '0'],
  ["221, 121, 117", '1'],
  ["197, 70, 68", '1'],
  ["218, 41, 28", '1'],
  ["177, 162, 202", '0'],
  ["142, 127, 174", '0'],
  ["236, 208, 181", '0'],
  ["240, 196, 160", '0'],
  ["250, 170, 141", '0'],
  ["248, 173, 109", '0'],
  ["229, 158, 109", '0'],
  ["189, 154, 122", '0'],
  ["181, 129, 80", '0'],
  ["255, 105, 0", '1'],
  ["166, 85, 35", '0'],
  ["107, 53, 41", '1'],
  ["105, 63, 35", '0'],
  ["78, 53, 36", '1'],
  ["120, 78, 144", '1'],
  ["248, 229, 154", '0'],
  ["213, 200, 151", '0'],
  ["239, 182, 97", '0'],
  ["255, 209, 0", '0'],
  ["255, 163, 0", '0'],
  ["229, 155, 220", '1'],
  ["177, 78, 181", '1'],
  ["174, 164, 111", '0'],
  ["174, 184, 98", '0'],
  ["181, 189, 0", '0'],
  ["239, 215, 229", '0'],
  ["94, 126, 41", '0'],
  ["231, 147, 183", '1'],
  ["207, 87, 138", '1'],
  ["51, 85, 37", '0'],
  ["45, 200, 77", '0'],
  ["0, 154, 68", '0'],
];



export type cartItemProt = {
  id: string;
  name: string;
  image: string;
  dimesions: [number, number];
  price: number;
  quantity: number;
};

export const cartItems: cartItemProt[] = [
  {
    id: "f3f232",
    name: "Example 2",
    image:
      "https://i.pinimg.com/236x/48/9b/2b/489b2bcef55fa81d1acb8a1be765e560--river-rocks-wild-things.jpg",
    dimesions: [3, 2],
    price: 3999,
    quantity: 1,
  },
];
