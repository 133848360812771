import React, { useState } from "react";
import { getImg } from "../../Components/Utility/getImg";
import { FaRulerCombined, FaCubes, FaRupeeSign } from "react-icons/fa";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

type Props = {
  hSize: number;
  vSize: number;
  cancelOverlay: Function;
  imgSrc: string;
};

function IRSPreview(props: Props) {
  const [num, setNum] = useState(1);

  return (
    <div className="IRS-maker">
      <div className="IRS-image">
        <div className="IRS-image-container">
          <img
            src={`${props.imgSrc}_bg${num}.png?` + performance.now()}
            alt=""
          />
        </div>

        <div className="IRS-button-strip">
          <div
            onClick={() => {
              setNum(num > 1 ? num - 1 : num);
            }}
            className="black-button but-gold"
          >
            <AiOutlineLeft color="black" size={"12px"} />
          </div>
          <div
            onClick={() => {
              props.cancelOverlay();
            }}
            className="black-button but-gold"
          >
            Back
          </div>
          <div
            onClick={() => {
              setNum(num < 2 ? num + 1 : num);
            }}
            className="black-button but-gold"
          >
            <AiOutlineRight color="black" size={"12px"} />
          </div>
        </div>
        <div className="IRS-details-strip" style={{ gap: "10px" }}>
          <div className="IRS-detail-snip flexed columned">
            <FaRulerCombined color="#ddb253" size={"12px"} />
            <div>
              Size: {Math.round(props.vSize * 25.6)} cm X{" "}
              {Math.round(props.hSize * 25.6)} cm
            </div>
          </div>
          <div className="IRS-detail-snip flexed columned">
            <FaRupeeSign color="#ddb253" size={"12px"} />
            Price: ₹ {props.vSize * props.hSize * 1900}
          </div>
          <div className="IRS-detail-snip flexed columned">
            <FaCubes color="#ddb253" size={"12px"} />
            <div>Pieces: {props.vSize * props.hSize * 1024} bricks</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IRSPreview;
