import React, { useEffect, useState } from "react";
import { BiLogIn } from "react-icons/bi";
import { Link } from "react-router-dom";

type Props = {
  login: Function;
  toaster: any;
};

export default function Login(props: Props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0]?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="koha-login">
      <div className="kl-header">Login</div>
      <div className="kl-body">
        <div>Email</div>
        <input
          id={"kohaMail"}
          type="text"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <div>Password</div>
        <input
          id={"kohaPass"}
          type="password"
          value={pass}
          onChange={(ev) => setPass(ev.target.value)}
        />

        <div
          onClick={() => {
            if (email && pass && props.toaster === "" && !loader) {
              setLoader(true);
              props.login(email.trim(), pass.trim(), () => {
                setLoader(false);
              });
            }
          }}
          className={`login-but ${
            email && pass && props.toaster === "" && !loader
              ? "clickable"
              : "unclickable blur"
          }`}
        >
          <BiLogIn size="20px" />
          <span>{loader ? "Logging in" : "Log in"}</span>
        </div>

        {window.location.pathname.indexOf("admin-panel") === -1 ? (
          <>
            <p className="clickable login-options">
              <Link to="/forgotpass">Forgot password</Link>
            </p>

            <p className="clickable login-options">
              New here? Click <Link to="/signup">here to sign-up</Link>
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
}
