import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getIcon, getImg } from "../../Utility/getImg";

import SideBarItem from "./sidebar-item";

import "./styles.scss";

type Props = {
  menu: any;
  logout: Function;
};

function SideBar(props: Props) {
  const location = useLocation();

  const [active, setActive] = useState(1);

  useEffect(() => {
    props.menu.forEach((element: any) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, [location.pathname]);

  const __navigate = (id: number) => {
    setActive(id);
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <img src={getImg("koha-logo-2.png")} alt="logo" />
        </div>

        <div className="sidebar-container">
          <div className="sidebar-items">
            {props.menu.map((item: any, index: number) => (
              <div key={index} onClick={() => __navigate(item.id)}>
                <SideBarItem active={item.id === active} item={item} />
              </div>
            ))}
          </div>

          <div className="sidebar-footer" onClick={() => props.logout()}>
            <span className="sidebar-item-label">Logout</span>
            <img
              src={getIcon("logout.svg")}
              alt="icon-logout"
              className="sidebar-item-icon"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
