import React from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";

type Props = {};

export const HowItWorks = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="page-img-headers">
          <img src={getImg("gear.jpg")} />
          <div className="overlay-text ">
            <div className="para-header">How does it work?</div>
            <div style={{ color: "white", fontStyle: "italic" }}>
              Instructions simplified
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="static-section koha-instructions">
          <div className="instruction-step  flexed columned ali-centered">
            <div className="para-header">Upload</div>
            <img src={getImg("Upload.png")} />
            <div className="static-para">
              {/* Once you hit Click to Create button at the top of the page,you
              will be taken to a page where you can upload your photos or select
              photos from our gallery.Once you upload your photo, click next for
              the customization,or change to reupload your photo. */}
              A picture of your choice.
            </div>
          </div>

          <div className="instruction-step flexed columned ali-centered">
            <div className="para-header">Customize</div>
            <img src={getImg("Create.png")} />
            <div className="static-para">
              {/* Using our Pixelate Software you can customize everything starting
              from the size to colours too much more. Use our crop option to
              zoom in to relevant areas of your photos to create focal points. */}
              Have fun exploring the various options and customise your picture
              with our pixelate tool.
            </div>
          </div>

          <div className="instruction-step flexed columned ali-centered">
            <div className="para-header">Order</div>
            <img src={getImg("Order.png")} />
            <div className="static-para">
              {/* Once you are happy with the customization of the photo, you can
              preview the photo and make any last-minute changes before placing
              the order. Once ordered, now sit back and relax. We will take it
              from here. */}
              Sit back and relax as we take it from here.
            </div>
          </div>
        </div>
      </FadeInSection>
      ;
    </div>
  );
};
