import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/AdminPanel/DashboardHeader";
import {
  calculateRange,
  sliceData,
} from "../../../Components/Utility/table-pagination";
import { couponType, getAllCoupons, updateCoupon } from "../../../APIs/API";
import { FaEdit, FaTrash } from "react-icons/fa";
import AddCoupon from "./AddCoupon";

type Props = {
  context: any;
};

export default function Coupons(props: Props) {
  const [couponList, setCouponList]: any[] = useState([]);
  const [search, setSearch] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getAllCoupons().then((res) => {
      if (res.data.success === true) {
        setPagination(calculateRange(res.data.data, 10));
        setCoupons(sliceData(res.data.data, page, 10));
        setCouponList(res.data.data);
      }
    });
  };

  // Search
  const __handleSearch = (event: any) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = couponList.filter(
        (item: any) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.couponid.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase())
      );
      setCoupons(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setCoupons(sliceData(couponList, new_page, 10));
  };

  const initiateUpdateCoupon = (item: couponType) => {
    props.context.setOverlay(
      true,
      "Update coupon",
      <AddCoupon
        context={props.context}
        data={item}
        refresh={() => refresh()}
      />
    );
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader
        context={props.context}
        onClick={() => {
          props.context.setOverlay(
            true,
            "Add new product",
            <AddCoupon context={props.context} refresh={() => refresh()} />
          );
        }}
        btnText="New coupon"
      />

      <div className="dashboard-content-container styledScroll">
        <div className="dashboard-content-header">
          <h2>Coupons List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search page..."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>ID</th>
            <th>NAME</th>
            <th>DESCRIPTION</th>
            <th>DISCOUNT</th>
            <th>STATUS</th>
            <th>EDIT/HIDE</th>
          </thead>

          {coupons.length !== 0 ? (
            <tbody>
              {coupons.map((coupon: any, index) => (
                <tr key={index}>
                  <td>
                    <span>{coupon.coupon_id}</span>
                  </td>
                  <td>
                    <span>{coupon.name}</span>
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    <span className="text-too-long">{coupon.description}</span>
                  </td>
                  <td>
                    <span>{coupon.discount}</span>
                  </td>
                  <td>
                    <span>{coupon.status ? "Active" : "Hidden"}</span>
                  </td>
                  <td>
                    <div className="flexed just-around" style={{ gap: "10px" }}>
                      <FaEdit
                        color="#ddb253"
                        size="28px"
                        onClick={() => {
                          initiateUpdateCoupon(coupon);
                        }}
                      />
                      <FaTrash
                        color="#ddb253"
                        size="24px"
                        onClick={() => {
                          let temp = {
                            coupon_id: coupon.coupon_id,
                            name: coupon.name,
                            description: coupon.description,
                            discount: coupon.discount,
                            status: false,
                          };
                          updateCoupon(temp);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {coupons.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item: any, index: number) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}
