import React from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

type Props = {};

export const AboutUs = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="page-img-headers">
          <img src={getImg("moon_bg.jpg")} />
          <div className="overlay-text para-header">Our story</div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="static-para">
          <p>
            Koha was born in 2021, with the vision to create products that are a
            blend of art, technology, and with a focus on functionality. The
            word Koha stands for Gift in Maori (the indigenous language of New
            Zealand) and is an expression of deep gratitude, affection, emotions
            and an ongoing relationship between giver and receiver. These
            emotions are embodied in every product designed and developed at
            Koha. Koha strives to empower creators and support every step of the
            creative journey.
          </p>
          <p>
            With sustainability as one of its core values, Koha has `made a
            conscious effort towards recycling and minimalizing its impact on
            the environment. Each product is custom-made with an emphasis on
            Zero Waste Policy and sustainable packaging.
          </p>
          <blockquote>
            We pride ourselves on our ability to create designs that are modern
            and timeless and will appeal to a broad range of customers
          </blockquote>
          <br />
          <br />
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="page-img-headers">
          <img
            src={getImg("our_promise1.jpg")}
            style={{ maxHeight: "450px" }}
          />
        </div>
        <div className="para-header">Our promise</div>
        <div className="static-para">
          <p>
            We at Koha pay close attention to the quality of materials we use in
            our products. Our meticulously engineered product is free from
            harmful chemicals, and of the highest quality, and to reduce our
            impact on the environment, Zero Waste Policy and Sustainable
            Packaging are adopted.
          </p>
          <p>
            We constantly strive to improve the quality of our product while
            making sure not to compromise on the quality while keeping the
            environment in mind.
          </p>
          <blockquote>
            THE PEOPLE OF MAORI HAVE GOT A SPIRITUAL BOND WITH MOTHER NATURE AND
            HAVE STRONG CONSERVATION ETHICS, WHICH WE FOLLOW HERE AT KOHA.
          </blockquote>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="para-header">Meet the team</div>
      </FadeInSection>

      <FadeInSection>
        <div className="team-member">
          <div className="member-personal">
            <img src={getImg("dummy.JPG")} />
            <div className="member-socials">
              {/* <a href="twitterlink">
                <FaTwitter color="#ddb253" size={"30px"} />
              </a> */}
              {/* <a href="instalink">
                <AiFillInstagram color="#ddb253" size={"35px"} />
              </a> */}
              <a href="mailto: pavan.rajanna@thekoha.com">
                <MdEmail color="#ddb253" size={"35px"} />
              </a>
              <a href="https://www.linkedin.com/in/pavan-rajanna-pavanrajanna/">
                <FaLinkedin color="#ddb253" size={"30px"} />
              </a>
            </div>
          </div>
          <div className="member-monologue">
            <div>
              <div className="para-header">Pavan Rajanna</div>
              <div>
                B.E. Mechanical, MSc. Aerospace Engineering (University of
                Sydney, Australia)
              </div>
              <div>
                As an aerospace engineer, I firmly believe in developing
                products that are an amalgamation of engineering and
                imagination. During my stint as a research engineer at the
                University of Sydney, I have stumbled upon numerous such
                technologies that have more often than not, left me in awe.The
                creativity, innovation and Engineering behind such products are
                unparalleled.
              </div>
              <br />
              <blockquote>
                "Most decorated Olympian of all time, Michael Phelps once quoted
                ‘Think Small to Accomplish Big Things’ and Koha is being built
                with those lines in mind."
              </blockquote>
              <br />
              <div>
                Under the guidance of some of the finest scientists and
                engineers, we have envisioned Koha to be an innovator in
                curating exquisite user-focused products with alluring design
                and mesmerizing functionality.
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="team-member">
          <div className="member-personal">
            <img src={getImg("dummy.JPG")} />
            <div className="member-socials">
              {/* <a href="twitterlink">
                <FaTwitter color="#ddb253" size={"30px"} />
              </a>
              <a href="instalink">
                <AiFillInstagram color="#ddb253" size={"35px"} />
              </a> */}

              <a href="mailto: prayag.bellur@thekoha.com">
                <MdEmail color="#ddb253" size={"35px"} />
              </a>
              <a href="https://www.linkedin.com/in/prayag-bellur">
                <FaLinkedin color="#ddb253" size={"30px"} />
              </a>
            </div>
          </div>
          <div className="member-monologue">
            <div>
              <div className="para-header">Prayag Bellur</div>
              <div>
                B.E. Mechanical, MSc. Production Engineering (Chalmers
                University, Sweden)
              </div>
              <br />
              <blockquote>
                Robert Kiyosaki in his famous book ‘Rich Dad Poor Dad’
                quotes,"Starting a business is like jumping out of an airplane
                without a parachute. In mid-air, the entrepreneur begins
                building a parachute and hopes it opens before hitting the
                ground."
              </blockquote>
              <br />
              <div>
                I could totally relate to this quote but when the two
                entrepreneurs are passionate engineers, they ‘predict’ that the
                parachute opens rather than hope. As a production engineer in
                Sweden, I could assimilate the state of the art manufacturing
                technologies and convoy KOHA in delivering quintessential
                products.
              </div>
              <div>
                {" "}
                KOHA is a long mission and will have an eternal place in the
                hearts of the people with its creative product line.
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
    </div>
  );
};
