import React, { useState, useEffect } from "react";
import {
  addBlog,
  getSignedRequest,
  blogDetails,
  updateBlog,
} from "../../../APIs/API";

type Props = {
  context: any;
  data?: blogDetails;
  refresh: Function;
};

const AddBlog = (props: Props) => {
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [clip, setClip] = useState<any>(null);
  const [meta, setMeta] = useState("");

  useEffect(() => {
    if (props.data) {
      setName(props.data.title);
      setClip(props.data.image);
      setInfo(props.data.info || "");
      setMeta(props.data.meta || "");
    }
  }, []);

  function addItem() {
    if (name && clip) {
      let data = {
        id: props.data ? props.data.id : 0,
        image: clip,
        status: true,
        title: name,
        info: info,
        meta: meta,
      };
      if (props.data) {
        updateBlog(data).then(() => {
          props.context.setItem("toasterText", "Blog has been updated");
          props.refresh();
          props.context.setOverlay(false);
        });
      } else {
        addBlog(data).then(() => {
          props.context.setItem("toasterText", "Blog has been added");
          props.refresh();
          props.context.setOverlay(false);
        });
      }
    } else {
      props.context.setItem("toasterText", "Please fill in all details");
    }
  }

  return (
    <div className="add-modal">
      <div className="add-modal-label">Blog title:</div>
      <input
        type="text"
        name="Blog title"
        id=""
        value={name}
        onChange={(ev) => setName(ev.target.value)}
      />
      <div className="add-modal-label">Blog description:</div>
      <textarea
        name="Blog info"
        style={{height: '150px'}}
        id=""
        value={info}
        onChange={(ev) => setInfo(ev.target.value)}
      />
      <div className="add-modal-label">Blog meta data:</div>
      <textarea
        name="Blog meta"
        style={{height: '150px'}}
        id=""
        value={meta}
        onChange={(ev) => setMeta(ev.target.value)}
      />
      <div className="add-modal-label">Blog image:</div>
      <div className="flexed ali-centered">
        <img id="clip" src={clip + "?" + performance.now()} alt="" />
        <input
          type="file"
          name="Blog img"
          onChange={async function (ev) {
            if (ev.target.files) {
              let el = document.getElementById("clip") as HTMLImageElement;

              getSignedRequest(ev.target.files[0])
                .then((data) => {
                  setClip(data + "?" + performance.now());
                })
                .catch((e) => {
                  props.context.setItem(
                    "toasterText",
                    "Image was not uploaded. Please try again later."
                  );
                });
            }
          }}
          id=""
        />
      </div>

      <div
        className="black-button"
        onClick={() => {
          addItem();
        }}
      >
        {props.data ? "Update" : "Add"}
      </div>
    </div>
  );
};

export default AddBlog;
