import React, { useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import { addAdmin } from "../../../APIs/API";

type Props = {
  context: any;
  refresh: Function;
};

const AddAdmin = (props: Props) => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [cPass, setCPass] = useState("");

  const checkForm = () => {
    //do validations
    if (
      fName !== "" &&
      lName !== "" &&
      email !== "" &&
      pass !== "" &&
      cPass !== "" &&
      pass === cPass
    )
      return true;
    else return false;
  };

  return (
    <div className="add-modal">
      <div>First Name</div>
      <input
        autoComplete="off"
        type="text"
        value={fName}
        onChange={(ev) => {
          setFname(ev.target.value);
        }}
      />

      <div>Last Name</div>
      <input
        autoComplete="off"
        type="text"
        value={lName}
        onChange={(ev) => {
          setLname(ev.target.value);
        }}
      />

      <div>Email</div>
      <input
        autoComplete="off"
        type="text"
        value={email}
        onChange={(ev) => {
          setEmail(ev.target.value);
        }}
      />

      <div>Password</div>
      <input
        autoComplete="off"
        type="password"
        value={email === "" ? "" : pass}
        onChange={(ev) => {
          setPass(ev.target.value);
        }}
      />

      <div>
        Confirm password
        {pass !== cPass ? (
          <span style={{ fontSize: "12px", color: "red" }}>
            &nbsp;(passwords do not match)
          </span>
        ) : null}
      </div>
      <input
        autoComplete="off"
        type="password"
        value={cPass}
        onChange={(ev) => {
          setCPass(ev.target.value);
        }}
      />

      <div
        className="black-button"
        onClick={() => {
          addAdmin(
            window.location.pathname.indexOf("admin-panel") !== -1,
            email,
            pass,
            fName + " " + lName
          ).then((res: any) => {
            if (res.data.success === true) {
              props.context.setItem("toasterText", "Admin added.");
              props.refresh();
              props.context.setOverlay(false);
            } else {
              props.context.setItem(
                "toasterText",
                "Something went wrong. Please try again later."
              );
              props.refresh();
              props.context.setOverlay(false);
            }
          });
        }}
      >
        <BiUserPlus size="20px" /> Add
      </div>
    </div>
  );
};

export default AddAdmin;
