import * as React from "react";
import { product_query } from "../../APIs/API";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

interface Props {
  context: any;
}

interface State {
  name: string;
  email: string;
  //   message: string;
}

class AskAQuestion extends React.Component<Props, State> {
  private messageRef: React.RefObject<HTMLTextAreaElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      //   message: "",
    };

    this.messageRef = React.createRef();
  }

  checkForm = () => {
    if (this.state.name !== "" && this.state.email !== "") return true;
    else return false;
  };

  sendMessage = () => {
    product_query(
      this.state.name,
      this.state.email,
      this.messageRef.current?.value ? this.messageRef.current?.value : ""
    ).then((res) => {
      this.props.context.setOverlay();
      this.props.context.setItem("toasterText", "Your query has been sent.");
    });
  };

  render() {
    return (
      <FadeInSection>
        <div className="koha-login">
          <form
            className="kl-body styledScroll"
            style={{
              overflowY: "auto",
              maxHeight: "65vh",
              padding: "0px 20px",
            }}
            autoComplete="off"
          >
            <div>Name</div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.name}
              onChange={(ev) =>
                this.setState({
                  name: ev.target.value,
                })
              }
            />

            <div>Email</div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.email}
              onChange={(ev) =>
                this.setState({
                  email: ev.target.value,
                })
              }
            />

            <div>Message</div>
            <textarea autoComplete="off" ref={this.messageRef} />
          </form>
          <div
            onClick={() => {
              this.sendMessage();
            }}
            className={`login-but ${
              this.checkForm() ? "clickable" : "unclickable  uneventful blur"
            }`}
          >
            <span>Send</span>
          </div>
        </div>
      </FadeInSection>
    );
  }
}

export default AskAQuestion;
