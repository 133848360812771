import React, { Component } from "react";
import { GoMail } from "react-icons/go";
import { BiPhone } from "react-icons/bi";
import {
  AiOutlineShoppingCart,
  AiFillInstagram,
  AiOutlineMenu,
} from "react-icons/ai";
import {
  FaFacebookF,
  FaTwitter,
  FaGoogle,
  FaLinkedin,
  FaRegUser,
  FaPinterest,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { getImg, getFile } from "./Components/Utility/getImg";
import { PixelateButton } from "./Components/Functional/PixelateButton";
import { pageNames } from "./Constants/constants";
import { addToNewsletter } from "./APIs/API";
import { Loader } from "./Loader";
import { detectInsta, detectMob } from "./Components/Utility/detectMob";

type Props = {
  children: any;
  context: any;
  changeHistory: Function;
};

type State = {
  openMenu: boolean;
  videoHide: boolean;
  msgAlt: boolean;
};

var interval:any = null;

export default class Layout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    

    this.state = {
      openMenu: false,
      videoHide: detectInsta() ? false : true,
      msgAlt: true,
    };
  }

  componentDidMount() {
     interval = setInterval(() => this.setState((prevState)=>({ msgAlt: !prevState.msgAlt })), 5000);
  }
  componentWillUnmount() {
    clearInterval(interval);
  }

  toggleMenu = (val: boolean) => {
    this.setState({
      openMenu: val,
    });
  };

  render() {
    return (
      <>
        {this.state.videoHide &&
        this.props.context.userName === "" &&
        window.location.pathname === "/" ? (
          // window.location.pathname.indexOf("resetpass") == -1 &&
          // window.location.pathname.indexOf("order") == -1 &&
          // window.location.pathname.indexOf("create") == -1 &&
          // window.location.pathname.indexOf("profile") == -1 &&
          // window.location.pathname.indexOf("itemdetails") == -1
          <div>
            <video
              className="preloader-video"
              src={
                detectMob()
                  ? "https://koha-direct-upload.s3.amazonaws.com/Koha_preloader_smaller.mp4"
                  : "https://koha-direct-upload.s3.amazonaws.com/Koha_preloader_small.mp4"
              }
              autoPlay
              onEnded={() => {
                this.setState({
                  videoHide: false,
                });
              }}
            />
          </div>
        ) : (
          <div className="koha-layout styledScroll">
            {this.props.context.layoutAltMsg&&this.state.msgAlt?<div className="free-shipping-banner">
              <div dangerouslySetInnerHTML={{__html:this.props.context.layoutAltMsg}} />

              
            </div>:<div className="free-shipping-banner">
              <div>Free shipping across India.</div>

              <div>For international shipping contact us.</div>
            </div>}
            <div className="koha-header">
              <div
                className="clickable"
                id="kohaLogo"
                onClick={() => this.props.changeHistory("/")}
              >
                <img src={getImg("koha-logo-2.png")} />
              </div>

              <div className="kohaNavBar" id="knbDesktop">
                <Link to="/aboutus" className="knb-item">
                  Our story
                </Link>
                <Link to="/howitworks" className="knb-item">
                  How it works
                </Link>
                <Link to="/gallery" className="knb-item">
                  Gallery
                </Link>
                {/* <Link to="/accessories" className="knb-item">
                  Accessories
                </Link> */}
                <Link to="/blog" className="knb-item">
                  Blog
                </Link>
                <Link to="/faq" className="knb-item">
                  FAQ
                </Link>
                <Link to="/contact" className="knb-item">
                  Contact us
                </Link>
                <Link to="/cart" className="knb-item">
                  <AiOutlineShoppingCart color="#ddb253" size={"25px"} />
                  {this.props.context.cartCount > 0 ? (
                    <div className="cart-count">
                      {this.props.context.cartCount}
                    </div>
                  ) : null}
                </Link>
                <Link to={"/profile"} className="knb-item">
                  <FaRegUser color="#ddb253" size={"20px"} />
                </Link>
                <Link
                  to="/create"
                  onClick={() => {
                    this.props.context.refreshIRS(true);
                  }}
                  className="knb-item"
                >
                  <PixelateButton onClick={() => {}} text={"Start here"} />
                </Link>
              </div>

              <div className="kohaNavBar" id="knbMobile">
                {window.location.pathname === "/create" ? null : (
                  <Link
                    to="/create"
                    onClick={() => {
                      this.toggleMenu(false);
                      this.props.context.refreshIRS(true);
                    }}
                    className="knb-item chatbot-style"
                  >
                    <PixelateButton
                      onClick={() => null}
                      text={"Start here"}
                      floater={true}
                    />
                  </Link>
                )}
                <Link
                  onClick={() => {
                    this.toggleMenu(false);
                  }}
                  to="/cart"
                  className="knb-item"
                >
                  <AiOutlineShoppingCart color="#ddb253" size={"25px"} />
                  {this.props.context.cartCount > 0 ? (
                    <div className="cart-count">
                      {this.props.context.cartCount}
                    </div>
                  ) : null}
                </Link>
                <Link
                  onClick={() => {
                    this.toggleMenu(false);
                  }}
                  to={"/profile"}
                  className="knb-item"
                >
                  <FaRegUser color="#ddb253" size={"20px"} />
                </Link>
                <div
                  className="knb-item centerized"
                  style={{
                    border: "1px solid rgba(255,255,255,.1)",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    background: "#191c1f",
                  }}
                  onClick={() => {
                    this.setState({
                      openMenu: !this.state.openMenu,
                    });
                  }}
                >
                  <AiOutlineMenu color="rgba(255,255,255,.55)" size={"20px"} />
                </div>
              </div>
            </div>

            <div
              className={`kohaNavDropDown${
                this.state.openMenu ? " visible" : " "
              }`}
            >
              <Link
                to="/aboutus"
                onClick={() => {
                  this.toggleMenu(false);
                }}
                className="knb-item"
              >
                Our story
              </Link>
              <Link
                to="/howitworks"
                onClick={() => {
                  this.toggleMenu(false);
                }}
                className="knb-item"
              >
                How it works
              </Link>
              <Link
                to="/gallery"
                onClick={() => {
                  this.toggleMenu(false);
                }}
                className="knb-item"
              >
                Gallery
              </Link>
              {/* <Link to="/accessories" className="knb-item">
                Accessories
              </Link> */}
              <Link to="/blog" onClick={() => {
                  this.toggleMenu(false);
                }} className="knb-item">
                  Blog
                </Link>
              <Link
                to="/faq"
                onClick={() => {
                  this.toggleMenu(false);
                }}
                className="knb-item"
              >
                FAQ
              </Link>
              <Link
                to="/contact"
                onClick={() => {
                  this.toggleMenu(false);
                }}
                className="knb-item"
              >
                Contact us
              </Link>
              {window.location.pathname == "/create" ? (
                <Link
                  to="/create"
                  onClick={() => {
                    this.toggleMenu(false);
                    this.props.context.refreshIRS(true);
                  }}
                  className="knb-item"
                >
                  <PixelateButton onClick={() => null} text={"Start here"} />
                </Link>
              ) : null}
            </div>

            {window.location.pathname == "/" ||
            window.location.pathname == "/create" ||
            (window.location.pathname == "/profile" &&
              this.props.context.userName === "") ? null : (
              <div className="koha-location">
                <>
                  <span
                    className="clickable"
                    onClick={() => {
                      this.props.changeHistory("/");
                    }}
                    style={{ color: "#ddb253" }}
                  >
                    Home
                  </span>
                  {window.location.pathname.indexOf("resetPass") !== -1
                    ? " / Reset Password"
                    : pageNames[
                        window.location.pathname as keyof typeof pageNames
                      ]}
                </>
              </div>
            )}
            {this.props.context.firstEntryFlag ? (
              this.props.children
            ) : (
              <Loader />
            )}

            <div className="koha-footer">
              <div id="footerContacts">
                <p style={{ fontSize: "20px" }}>
                  <u>LETS CHAT!</u>
                </p>

                <p>Tell us about your project</p>

                <p>
                  <a
                    href="mailto: info@thekoha.com"
                    className="noMakeup centerized"
                  >
                    <GoMail
                      color="#ddb253"
                      style={{ marginRight: "10px" }}
                      size={"20px"}
                    />
                    info@thekoha.com
                  </a>
                </p>

                <p>
                  <a href="tel:9916299016" className="noMakeup centerized">
                    <BiPhone
                      color="#ddb253"
                      size={"20px"}
                      style={{ marginRight: "5px", width: "35px" }}
                    />{" "}
                    +91-9916299016 / +91-7676869730
                  </a>
                </p>

                <p
                  className="flexed just-around ali-centered"
                  style={{ maxWidth: "300px", margin: "auto" }}
                >
                  <a href="https://www.facebook.com/profile.php?id=100084668861156&sk=about_contact_and_basic_info">
                    <FaFacebookF color="#ddb253" size={"20px"} />
                  </a>
                  <a href="https://in.pinterest.com/0e4omdk5j63box81g9fu6ztmdeequk/">
                    <FaPinterest color="#ddb253" size={"20px"} />
                  </a>
                  <a href="https://www.google.com/search?q=koha+decor">
                    <FaGoogle color="#ddb253" size={"20px"} />
                  </a>
                  <a href="https://www.instagram.com/the.koha/">
                    <AiFillInstagram color="#ddb253" size={"25px"} />
                  </a>
                  <a href="https://www.linkedin.com/company/koha-decor/?viewAsMember=true">
                    <FaLinkedin color="#ddb253" size={"20px"} />
                  </a>
                </p>
              </div>

              <div id="footerNewsLetter">
                <p style={{ fontSize: "20px" }}>
                  <u>Subscribe to our Newsletter</u>
                </p>

                <p>Stay in the loop for upcoming products</p>

                <div>
                  <input
                    type={"text"}
                    id="newsLetterInput"
                    placeholder="Your email"
                    onChange={() => {}}
                    style={{ margin: 0 }}
                  />
                  <input
                    className="submitButtonFooter"
                    type={"button"}
                    value="Submit"
                    onClick={() => {
                      let x = document.getElementById(
                        "newsLetterInput"
                      ) as HTMLInputElement;
                      addToNewsletter({
                        userid: x.value,
                        news_letter: true,
                      });
                    }}
                  />
                </div>
              </div>

              <div id="policyInfo">
                <p style={{ fontSize: "20px" }}>
                  <u>MORE</u>
                </p>

                <p>
                  <Link to="/shipping">Shipping Information</Link>
                </p>

                <p>
                  <Link to="/privacy">Privacy Policy</Link>
                </p>

                <p>
                  <Link to="/return">Return Policy</Link>
                </p>
              </div>
            </div>

            <div className="koha-credits">
              Copyright © 2022, Koha Website. All Rights Reserved – Designed by
              Koha and Developed by Bleam Technologies Private Limited
            </div>
          </div>
        )}
      </>
    );
  }
}
