import React, { Component } from "react";
import { getCart, login } from "../../APIs/API";

const Context = React.createContext();

class KohaContext extends Component {
  constructor(props) {
    super(props);

    const initialState = {
      firstEntryFlag: false,
      userName: "",
      userDetails: {
        fName: "",
        lName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pin: "",
      },
      cart: {},
      admin: false,
      cartCount: 0,
      toasterText: "",
      orderStatus: true,
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },
      itemDetails: "",
      irsImg: "",
      layoutAltMsg: null
    };

    this.state = {
      firstEntryFlag: false,
      userName: "",
      userDetails: {
        fName: "Dummy",
        lName: "Tester",
        email: "dummy@tester.com",
        phone: "1234567890",
        address: "#1011",
        city: "bangalore",
        state: "karnataka",
        country: "india",
        pin: "560099",
      },
      cart: {},
      admin: false,
      cartCount: 0,
      toasterText: "",
      orderStatus: true,
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },
      itemDetails: "",
      irsImg: "",
      irsDetails: {
        vSize: 1,
        hSize: 1,
        autoColor: true,
        colorNos: 20,
        palette: [],
        userColors: [],
        brightness: 50,
        contrast: 50,
        saturation: 50,
        zoomCrop: 100,
        autoFit: true,
        imageZoom: 100,
        selectedControl: "size",
        legoedImg: "",
        lastClick: 0,
        stillLoading: false,
        layoutAltMsg: null
      },

      //functions
      setItem: (key, val) => {
        this.setState({
          [key]: val,
        });
        sessionStorage.setItem("currentState", JSON.stringify(this.state));
      },
      setItemDetails: (item) => {
        this.setState(
          {
            itemDetails: item,
          },
          () => {
            sessionStorage.setItem("currentState", JSON.stringify(this.state));
            if (
              window.location.pathname.indexOf("itemdetails") !== -1
              // &&
              // this.state.userName !== ""
            ) {
              // window.location.reload();
            }
          }
        );
      },
      refreshIRS: (val = false) => {
        this.setState(
          {
            irsDetails: {
              vSize: 1,
              hSize: 1,
              autoColor: true,
              colorNos: 20,
              palette: [],
              userColors: [],
              brightness: 50,
              contrast: 50,
              saturation: 50,
              zoomCrop: 100,
              autoFit: true,
              imageZoom: 100,
              selectedControl: "size",
              legoedImg: "",
            },
            irsImg: "",
          },
          () => {
            sessionStorage.setItem("currentState", JSON.stringify(this.state));
            if (val) {
              window.location.reload();
            }
          }
        );
      },
      login: (uName, pass, callback) => {
        if (uName.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) && pass !== "") {
          login(false, uName, pass)
            .then((res) => {
              callback();
              if (res.data.success === true) {
                this.setState({
                  userName: uName,
                  toasterText: res.data.message,
                  admin: res.data.data,
                });
                this.state.updateContextCart(uName);
                sessionStorage.setItem(
                  "currentState",
                  JSON.stringify(this.state)
                );
              } else {
                this.setState({
                  toasterText: "Login failed. Please try again later.",
                });
              }
            })
            .catch((res) => {
              callback();
              this.setState({ toasterText: res.response.data.errors });
            });
        } else {
          callback();
          this.setState({
            toasterText: "Enter valid email/password",
          });
        }
      },
      logout: () => {
        sessionStorage.removeItem("currentState");
        this.setState({
          ...initialState,
        });
      },
      setOverlay: (
        show = false,
        title = "",
        body = null,
        buttons = [],
        buttonCentered = false
      ) => {
        if (show === false) {
          this.setState({
            overlayItems: { title: "", body: null, buttons: [], show: false },
          });
        } else {
          this.setState({
            overlayItems: {
              title: title,
              body: body,
              buttons: buttons,
              show: true,
              buttonCentered: buttonCentered,
            },
          });
        }
      },
      updateContextCart: (uName = this.state.userName) => {
        if (uName !== "")
          getCart(uName).then((res) => {
            this.setState(
              {
                cart: res.data,
                cartCount: Object.keys(res.data).length || 0,
              },
              () => {
                sessionStorage.setItem(
                  "currentState",
                  JSON.stringify(this.state)
                );
              }
            );
          });
      },
    };
  }

  // componentWillUnmount() {
  //   this.state.logout();
  // }

  componentDidMount() {
    let temp = sessionStorage.getItem("currentState");
    if (temp === null) {
      this.firstEntry();
    }
    this.consequentEntries();
  }

  firstEntry = () => {
    this.state.logout();
    this.setState(
      {
        firstEntryFlag: true,
      },
      () => {
        sessionStorage.setItem("currentState", JSON.stringify(this.state));
      }
    );
  };

  consequentEntries = () => {
    let temp = JSON.parse(sessionStorage.getItem("currentState"));
    this.setState({
      ...temp,
      firstEntryFlag: true,
      overlayItems: {
        show: false,
        title: "",
        body: null,
        buttons: [],
        buttonCentered: false,
      },
    });
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

const ContextHOC = (SomeComponent, props) => {
  return (
    <Context.Consumer>
      {(val) => <SomeComponent {...val} {...props} />}
    </Context.Consumer>
  );
};

export { KohaContext, ContextHOC };
