import React from "react";
import { FaRegWindowClose } from "react-icons/fa";

export type OverlayButton = {
  title: string;
  onClick: any;
  inactive?: boolean;
};

type Props = {
  header: any;
  body: any;
  buttons: OverlayButton[];
  buttonCentered?: boolean;
  exit: Function;
};

export default function OverlayBox(props: Props) {
  return (
    <div
      className="overlay"
      onClick={(ev) => {
        let x = ev.target as any;
        if (x.classList.contains("overlay")) props.exit();
      }}
    >
      <div className="modal">
        <FaRegWindowClose
          onClick={() => {
            props.exit();
          }}
          className="modal-closer clickable"
          color="#666"
          size="24px"
        />
        <div className="modal-header">{props.header}</div>
        <div className="modal-body">{props.body}</div>
        <div
          className={`modal-buttons ${props.buttonCentered ? "centered" : ""}`}
        >
          {props.buttons.map((b: OverlayButton, i: number) => {
            return (
              <div
                key={i}
                className={`black-button ${b.inactive ? "inactive" : ""}`}
                onClick={() => b.onClick()}
              >
                {b.title}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
