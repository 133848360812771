import React,{useEffect,useState} from 'react'
import BlogItem from './BlogItem'

import {getAllBlogs, blogDetails} from "./../../APIs/API"

type Props = {}

export const Blog = (props: Props) => {

  const [blogs, setBlogs]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getAllBlogs().then((res) => {
      if (res.data.success === true) {
        setBlogs(res.data.data.reverse())
      }
    });
  };

  return (
    <div className="blog-space">
        {blogs.map((e:blogDetails)=> e.status?<BlogItem key={e.id} data={e} />:null)}
    </div>
    
  )
}