import * as React from "react";
import { BiUserPlus } from "react-icons/bi";
import {
  addOrder,
  legofy,
  orderPlacementType,
  updateCart,
} from "../../APIs/API";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

interface Props {
  context: any;
  price: number;
  discount: number;
}

interface State {
  email: string;
  fName: string;
  lName: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pin: string;
  apartment: string;
  cart: any;
}

class AddOrderModel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      fName: "",
      lName: "",
      phone: "",
      apartment: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pin: "",
      cart: null,
    };
  }

  checkForm = () => {
    if (
      (this.state.email !== "" || this.props.context.userName !== "") &&
      this.state.fName !== "" &&
      this.state.lName !== "" &&
      this.state.phone !== "" &&
      this.state.address !== "" &&
      this.state.city !== "" &&
      this.state.state !== "" &&
      this.state.country !== "" &&
      this.state.pin !== ""
    )
      return true;
    else return false;
  };

  generateIrsFiles = (irsConfig: any) => {};

  // componentDidMount(): void {
  // this.generateCsv();
  // }

  generateCsv = () => {
    let x = Object.values(this.props.context.cart);
    x.map((e: any, i: number) => {
      if (e.irsConfig) {
        e.irsConfig.finalize = true;
        legofy(e.irsConfig).then((res) => {
          e.csv = e.irsConfig.imgpath + "_csv";
          e.palette = e.irsConfig.imgpath + "_color_code";
          e.output = e.irsConfig.imgpath + "_output";
        });
      }
    });
    this.setState({
      cart: Object.assign({}, x),
    });
  };

  placeOrder = () => {
    let temp: orderPlacementType = {
      items: this.props.context.cart,
      userid: this.state.email.trim() || this.props.context.userName.trim(),
      country: this.state.country.trim(),
      firstname: this.state.fName.trim(),
      lastname: this.state.lName.trim(),
      address: this.state.address.trim(),
      apartment: this.state.apartment.trim(),
      city: this.state.city.trim(),
      state: this.state.state.trim(),
      pincode: parseInt(this.state.pin.trim()),
      phone: parseInt(this.state.phone.trim()),
      price: this.props.price,
      discount: this.props.discount,
    };
    addOrder(temp).then((res) => {
      // console.log("https://testpay.easebuzz.in/pay/" + res.data.data);
      // setTimeout(() => {
      window.location.href = "https://pay.easebuzz.in/pay/" + res.data.data;
      // }, 2000);
    });
  };

  render() {
    return (
      <FadeInSection>
        <div className="koha-login">
          {/* <div className="kl-header">Order details</div> */}
          <form
            className="kl-body styledScroll"
            style={{
              overflowY: "auto",
              maxHeight: "40vh",
              padding: "0px 20px",
            }}
            autoComplete="off"
          >
            <div>
              First Name <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.fName}
              onChange={(ev) =>
                this.setState({
                  fName: ev.target.value,
                })
              }
            />

            <div>
              Last Name <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.lName}
              onChange={(ev) =>
                this.setState({
                  lName: ev.target.value,
                })
              }
            />

            {this.props.context.userName === "" ? (
              <>
                <div>
                  Email <span className="red-imp">*</span>
                </div>
                <input
                  autoComplete="off"
                  type="text"
                  value={this.state.email}
                  onChange={(ev) =>
                    this.setState({
                      email: ev.target.value,
                    })
                  }
                />
              </>
            ) : null}

            <div>
              Phone <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type={"number"}
              value={this.state.phone}
              onChange={(ev) =>
                this.setState({
                  phone: ev.target.value,
                })
              }
            />

            <div>
              Apartment <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.apartment}
              onChange={(ev) =>
                this.setState({
                  apartment: ev.target.value,
                })
              }
            />

            <div>
              Address <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.address}
              onChange={(ev) =>
                this.setState({
                  address: ev.target.value,
                })
              }
            />

            <div>
              City <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.city}
              onChange={(ev) =>
                this.setState({
                  city: ev.target.value,
                })
              }
            />

            <div>
              State <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.state}
              onChange={(ev) =>
                this.setState({
                  state: ev.target.value,
                })
              }
            />

            <div>
              Country <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type="text"
              value={this.state.country}
              onChange={(ev) =>
                this.setState({
                  country: ev.target.value,
                })
              }
            />

            <div>
              Pincode <span className="red-imp">*</span>
            </div>
            <input
              autoComplete="off"
              type={"number"}
              value={this.state.pin}
              onChange={(ev) =>
                this.setState({
                  pin: ev.target.value,
                })
              }
            />
          </form>
          <div
            onClick={() => {
              this.placeOrder();
            }}
            className={`login-but ${
              this.checkForm() ? "clickable" : "unclickable  uneventful blur"
            }`}
          >
            <BiUserPlus size="20px" />
            <span>Place Order</span>
          </div>
        </div>
      </FadeInSection>
    );
  }
}

export default AddOrderModel;
