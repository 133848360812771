import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/AdminPanel/DashboardHeader";
import {
  calculateRange,
  sliceData,
} from "../../../Components/Utility/table-pagination";
import { getAllOrders, legofy, updateOrder } from "../../../APIs/API";
import { FaFileDownload } from "react-icons/fa";
import Select from "react-select";

type Props = {
  context: any;
};

function Orders(props: Props) {
  const [orderList, setOrderList]: any[] = useState([]);
  const [orders, setOrders]: any[] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Order Placed", value: "Order Placed" },
    { label: "In transit", value: "In transit" },
    { label: "Delivered", value: "Delivered" },
    { label: "Awaiting payment", value: "Awaiting payment" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "1px solid #ddb253",
      color: "#ddb253 !important",
      padding: 10,
      backgroundColor: "#000 !important",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#000",
      border: "1px solid #ddb253 !important",
      color: "#ddb253 !important",
      width: "100%",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "#ddb253",
      backgroundColor: "#000",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "#ddb253",
      backgroundColor: "#000",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      color: "#ddb253",
      backgroundColor: "#ddb253",
    }),

    singleValue: (provided: any, state: any) => {
      const color = "#ddb253 !important";
      const width = "100% !important";

      return { ...provided, color, width };
    },
  };

  const refresh = () => {
    getAllOrders().then((res) => {
      setPagination(calculateRange(Object.values(res.data).reverse(), 5));
      setOrderList(Object.values(res.data).reverse());
      setOrders(sliceData(Object.values(res.data).reverse(), page, 5));
    });
  };
  // Search
  const __handleSearch = (event: any) => {
    let search = event.target.value;
    if (event.target.value !== "") {
      let search_results = orderList.filter(
        (item: any) =>
          item.firstname.toLowerCase().includes(search.toLowerCase()) ||
          item.lastname.toLowerCase().includes(search.toLowerCase()) ||
          item.userid.toLowerCase().includes(search.toLowerCase()) ||
          JSON.stringify(item.bookingid).includes(search)
      );
      setOrders(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setOrders(sliceData(orderList, new_page, 5));
  };

  //Genreate irs outputs
  const generateCsv = (name: string, config: any) => {
    config.finalize = true;
    setLoading(true);
    legofy(config).finally(() => {
      setLoading(false);
      // setTimeout(() => {
      let x = document.getElementById(name) as HTMLAnchorElement;
      if (x) {
        x.click();
      }
      // }, 5000);
    });
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader context={props.context} onClick={() => {}} btnText="" />

      <div className="dashboard-content-container styledScroll">
        <div className="dashboard-content-header">
          <h2>Orders List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              placeholder="Search page..."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>ORDER ID</th>
            <th>USER</th>
            <th>ORDER ITEMS</th>
            <th>TOTAL AMOUNT</th>
            <th>DELIVERY ADDRESS</th>
            <th>STATUS</th>
          </thead>

          {orders.length !== 0 ? (
            <tbody>
              {orders.map((order: any, index: number) => (
                <tr key={index}>
                  <td>
                    <span>{order.bookingid}</span>
                  </td>
                  <td>
                    <span>
                      {order.userid}
                      <br />
                      {order.firstname} {order.lastname}
                    </span>
                  </td>
                  <td>
                    <span>
                      {order.items &&
                        Object.values(order.items).map((e: any, i: number) => {
                          return (
                            <div
                              key={i}
                              className={`each-order-item flexed ${
                                e.irsConfig ? "just-between" : ""
                              } `}
                              style={{ marginTop: i !== 0 ? "10px" : "0px" }}
                            >
                              <img src={e.image} alt="" />
                              <span>
                                {e.name}
                                <br />
                                {e.dimesions.x + " X " + e.dimesions.y}
                                <br />₹ {e.price}
                                <br />
                                {e.quantity} No(s)
                              </span>
                              {e.irsConfig ? (
                                <span>
                                  <FaFileDownload
                                    className={`${
                                      loading
                                        ? "unclickable uneventful blur"
                                        : "clickable"
                                    }`}
                                    onClick={() => {
                                      generateCsv(e.name + "_dl", e.irsConfig);
                                    }}
                                    color="#ddb263"
                                    size={"26px"}
                                  />
                                  <a
                                    id={e.name + "_dl"}
                                    href={`${e.irsConfig.imgpath}_zip`}
                                    download
                                    style={{ visibility: "hidden" }}
                                  />
                                </span>
                              ) : null}
                            </div>
                          );
                        })}
                    </span>
                  </td>
                  <td>
                    <span>₹ {order.price}</span>
                  </td>
                  <td>
                    <span>
                      {order.phone}
                      <br />
                      {`${order.apartment}, ${order.address}, ${order.city}, ${order.state}, ${order.country}-${order.pincode}`}
                    </span>
                  </td>
                  <td style={{ minWidth: "170px" }}>
                    <span>
                      <Select
                        options={statusOptions}
                        value={{
                          label: order.status || "Pending",
                          value: order.status || "Pending",
                        }}
                        styles={customStyles}
                        onChange={(val) => {
                          if (val) {
                            updateOrder(order.bookingid, val.value).then(() => {
                              refresh();
                            });
                          }
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {orders.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item: any, index: number) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
