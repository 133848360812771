import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/AdminPanel/DashboardHeader";
import {
  calculateRange,
  sliceData,
} from "../../../Components/Utility/table-pagination";
import { BiBlock, BiUndo } from "react-icons/bi";
import { blockUser, getUsers } from "../../../APIs/API";

type Props = {
  context: any;
};

function Users(props: Props) {
  const [userList, setUserList]: any[] = useState([]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination]: any[] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getUsers().then((res) => {
      if (res.data.success === true) {
        setPagination(calculateRange(Object.values(res.data.data), 10));
        setUsers(sliceData(Object.values(res.data.data), page, 10));
        setUserList(Object.values(res.data.data));
      }
    });
  };
  // Search
  const __handleSearch = (event: any) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = userList.filter(
        (item: any) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.userid.toLowerCase().includes(search.toLowerCase())
      );
      setUsers(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page: number) => {
    setPage(new_page);
    setUsers(sliceData(userList, new_page, 10));
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader context={props.context} onClick={() => {}} btnText="" />

      <div className="dashboard-content-container styledScroll">
        <div className="dashboard-content-header">
          <h2>Users List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search page..."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>STATUS</th>
            <th>ORDERS</th>
            <th>TOGGLE STATUS</th>
          </thead>

          {users.length !== 0 ? (
            <tbody>
              {users.map((order: any, index: number) => (
                <tr key={index}>
                  <td>
                    <span>{order.name}</span>
                  </td>
                  <td>
                    <span>{order.userid}</span>
                  </td>
                  <td>
                    <span>{order.status ? "Verified" : "On hold"}</span>
                  </td>
                  <td>
                    <span>{order.orders}</span>
                  </td>
                  <td>
                    <span
                      className="flexed just-centered clickable"
                      onClick={() => {
                        blockUser(
                          order.userid,
                          order.status ? false : true
                        ).then((res) => {
                          refresh();
                        });
                      }}
                    >
                      {order.status ? (
                        <BiBlock color="#ddb253" size="26px" />
                      ) : (
                        <BiUndo color="#ddb253" size="26px" />
                      )}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {users.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item: any, index: number) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Users;
