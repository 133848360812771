import FormData from "form-data";
import axios from "axios";

// export const BASE_URL = "https://54.165.121.0:8000";
export const BASE_URL = "https://thekoha.com/api";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const axInstance = axios.create({
  baseURL: BASE_URL,
});

export const errorHandler = (err: any, toaster: Function) => {
  console.log(err);
  toaster();
};

export const login = (admin: boolean, userid: string, password: string) => {
  return axInstance.post("/users/login", {
    userid,
    password: password,
  });
};

export const adminLogin = (
  admin: boolean,
  userid: string,
  password: string
) => {
  return axInstance.post("/users/admin-login", {
    userid,
    password: password,
  });
};

export const signUp = (
  admin: boolean,
  userid: string,
  password: string,
  name: string
) => {
  return axInstance.post("/users/", {
    userid,
    password: password,
    name,
  });
};

export const addAdmin = (
  admin: boolean,
  userid: string,
  password: string,
  name: string
) => {
  return axInstance.post("/users/add-admin", {
    userid,
    password: password,
    name,
  });
};

export const changePass = (
  admin: boolean,
  userid: string,
  oPass: string,
  nPass: string
) => {
  return axInstance.put(`/users/account-update-password`, {
    userid,
    old_password: oPass,
    new_password: nPass,
  });
};

export const resetPass = (admin: boolean, userid: string, pass: string) => {
  return axInstance.put(`/users/update-password`, {
    userid,
    password: pass,
  });
};

export const forgotPass = (admin: boolean, userid: string) => {
  return axInstance.post(`/users/forgot-password`, {
    userid,
  });
};

export const getUsers = () => {
  return axInstance.get("/admin/user-details");
};

export const getAdmins = () => {
  return axInstance.get("/admin/admin-details");
};

export const blockUser = (email: string, flag: boolean) => {
  return axInstance.post("/users/unverify", { email, flag });
};

export type orderPlacementType = {
  items: any;
  userid: string;
  country: string;
  firstname: string;
  lastname: string;
  address: string;
  apartment: string;
  city: string;
  state: string;
  pincode: number;
  phone: number;
  price: number;
  discount: number;
};

export const addOrder = (data: orderPlacementType) => {
  return axInstance.post("/users/booking", {
    ...data,
  });
};

export const updateOrder = (bookingid: number, status: string) => {
  return axInstance.put("/users/booking-update", {
    bookingid,
    status,
  });
};

export const getAllOrders = () => {
  return axInstance.get("/users/get-orders");
};

export const getUserOrders = (userid: string) => {
  return axInstance.get(`/users/get-orders/${userid}`);
};

export type cartItemType = {
  id: string;
  name: string;
  image: string;
  dimesions: { x: number; y: number };
  irsConfig?: {};
  price: number;
  quantity: number;
};

export const updateCart = (userid: string, cart: any) => {
  return axInstance.post("/users/update-cart", {
    userid,
    cart,
  });
};

export const getCart = (userid: string) => {
  return axInstance.get(`/users/get-cart/${userid}`);
};

export type itemDetailsType = {
  name: string;
  id: string;
  image: string;
  status: boolean;
  quantity?: number;
  price?: number;
  availableSizes: { [name: number]: itemSizeType };
};

export type itemDetailsType3 = {
  //this is wat we get from api
  itemid: number;
  item_link: string;
  price: number;
  quantity: number;
  status: boolean;
  sizes: { [name: number]: itemSizeType };
  name: string;
  description?: string;
};

export type itemSizeType = {
  size: string;
  image: any;
  dimensions: { x: number; y: number };
};

export const addProduct = (data: itemDetailsType3) => {
  return axInstance.post("/admin/add-item", {
    ...data,
  });
};

export const updateProduct = (data: itemDetailsType3) => {
  return axInstance.put("/admin/update-item", { ...data });
};

export const getAllProducts = () => {
  return axInstance.get("/admin/inventory-list");
};

export type couponType = {
  coupon_id: string;
  description: string;
  discount: number;
  name: string;
  status: boolean;
};

export const addCoupon = (item: couponType) => {
  return axInstance.post("/admin/add-coupon", { ...item });
};

export const updateCoupon = (item: couponType) => {
  return axInstance.put("/admin/update-coupon", { ...item });
};

export const getAllCoupons = () => {
  return axInstance.get("/admin/get_coupons");
};

export const getCoupon = (cid: string) => {
  return axInstance.get("/admin/get-coupon/" + cid);
};

export type irsType = {
  image_filename: string;
  imgpath: string;
  baseplate_size: number[];
  num_of_colors: number;
  user_colors: any[];
  autoselect: string;
  autofit: string;
  zoom: number;
  finalize: boolean;
  bright: number;
  contrast: number;
  saturation: number;
};

export const legofy = (data: irsType) => {
  return axInstance.post(
    "/users/irs-edit",
    {
      ...data,
    },
    {
      cancelToken: source.token,
    }
  );
};

export const irsPreview = (data: irsType) => {
  return axInstance.post("/users/irs-preview", { ...data });
};

export const addToNewsletter = (data: {
  userid: string;
  news_letter: boolean;
}) => {
  return axInstance.put("/users/update-news-letter", {
    ...data,
  });
};

export const feedback_query = (
  name: string,
  email: string,
  phone: string,
  time: string,
  subject: string,
  message: string
) => {
  return axInstance.post("/users/feedback_query", {
    name,
    email,
    phone,
    time,
    subject,
    message,
  });
};

export type blogDetails = {
  id: number,
  title: string,
  image: string,
  info: string,
  meta: string,
  status: boolean
}

export const getAllBlogs = () => {
  return axInstance.get("/admin/get_blogs");
}

export const addBlog = (data: blogDetails) => {
  return axInstance.post("/admin/add-blog", {
    ...data,
  });
};

export const updateBlog = (data: blogDetails) => {
  return axInstance.put("/admin/update-blog", { ...data });
};

export const product_query = (name: string, email: string, message: string) => {
  return axInstance.post("/users/product_query", {
    name,
    email,
    message,
  });
};

export function getSignedRequest(file: any) {
  return axInstance
    .post("/admin/upload-image", { file_type: file.type })
    .then(async (res: any) => {
      if (res.status === 200 || res.status === 201) {
        let x = await uploadFile(
          file,
          res.data.data,
          res.data.data.url + res.data.data.fields.key
        );
        if (x) {
          return res.data.data.url + res.data.data.fields.key;
        }
      } else {
        console.log("Oops");
        return null;
      }
    });
}

async function uploadFile(file: any, s3Data: any, url: string) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", s3Data.url);
  var postData = new FormData() as any;
  for (let key in s3Data.fields) {
    postData.append(key, s3Data.fields[key]);
  }
  postData.append("file", file);
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 204) {
        return url;
      } else {
        return "";
      }
    }
    return true;
  };
  xhr.send(postData);
  return true;
}
