import React from "react";
import { Accordian } from "../../Components/Functional/Accordian";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg } from "../../Components/Utility/getImg";
import * as faqData from "../../Constants/FAQ";

type Props = {};

export const FAQ = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="page-img-headers">
          <img src={getImg("faq-banner.png")} />
          <div className="overlay-text para-header">FAQ</div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="faq-box">
          <h3>About pixelate</h3>
          {faqData.pixelate.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>About product</h3>
          {faqData.product.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>
            Tips and tricks
            <h5>
              Wondering which photo to use for Pixelate? Don’t worry, we got you
              covered. Have a look at the below tips. <br />
              Pixelate looks best when viewed from few steps away.
            </h5>
          </h3>
          {faqData.trickrtreat.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>Shipping and delivery</h3>
          {faqData.shipping.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>Privacy and data policy</h3>
          {faqData.privacy.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>Returns and refund policy</h3>
          {faqData.returns.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>

        <div className="faq-box">
          <h3>Contact us</h3>
          {faqData.contact.map((e: faqData.faqItem, i: number) => (
            <Accordian question={e.question} answer={e.answer} />
          ))}
        </div>
      </FadeInSection>
    </div>
  );
};
