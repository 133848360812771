import React, { Component } from "react";
import {
  FaMagic,
  FaSearch,
  FaExpandArrowsAlt,
  FaRulerCombined,
  FaPalette,
  FaArrowsAltH,
  FaArrowsAltV,
  FaPlusCircle,
  FaMinusCircle,
  FaInfoCircle,
  FaCubes,
  FaRupeeSign,
} from "react-icons/fa";
import { irsPreview, legofy, updateCart } from "../../APIs/API";
import { getIcon, getImg } from "../../Components/Utility/getImg";
import { IRSColors } from "../../Constants/constants";
import IRSPreview from "./IRSPreview";

type Props = {
  context: any;
  uploadImage: Function;
  srcImage: string;
  initiateStartOver: Function;
};

type State = {
  vSize: number;
  hSize: number;
  autoColor: boolean;
  colorNos: number;
  palette: [string, any][];
  userColors: string[];
  brightness: number;
  contrast: number;
  saturation: number;
  zoomCrop: number;
  autoFit: boolean;
  imageZoom: number;
  selectedControl: string;
  legoedImg: string;
  lastClick: any;
  stillLoading: boolean;
};

const delay = 1500;

class IRS extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      vSize: 1,
      hSize: 1,
      autoColor: true,
      colorNos: 20,
      palette: IRSColors,
      userColors: [],
      brightness: 50,
      contrast: 50,
      saturation: 50,
      zoomCrop: 100,
      autoFit: true,
      imageZoom: 100,
      selectedControl: "size",
      legoedImg: this.props.context.irsImg || this.props.srcImage,
      lastClick: 0,
      stillLoading: false,
    };
  }

  componentDidMount(): void {
    this.setState({
      ...this.props.context.irsDetails,
    });
    this.legofication();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  properColors = () => {
    let userColorList = this.state.palette
      .filter((e) => {
        if (e[1] == 1) return e;
        else return;
      })
      .map((e) => {
        return e[0];
      });

    let temp = {
      image_filename: this.props.srcImage.split("com/")[1],
      imgpath: this.props.srcImage,
      baseplate_size: [this.state.hSize, this.state.vSize],
      num_of_colors: this.state.colorNos,
      user_colors: userColorList,
      autoselect: this.state.autoColor ? "on" : "off",
      autofit: this.state.autoFit ? "on" : "off",
      zoom: this.state.zoomCrop,
      finalize: false,
      bright: this.state.brightness,
      contrast: this.state.contrast,
      saturation: this.state.saturation,
    };
    return temp;
  };

  legofication = (preview?: boolean) => {
    if (this.state.stillLoading) return;
    let temp = this.properColors();
    this.setState({
      stillLoading: true,
    });
    if (preview) {
      irsPreview(temp)
        .then((res) => {
          this.props.context.setOverlay(
            true,
            "",
            <IRSPreview
              imgSrc={this.props.context.irsImage || this.props.srcImage}
              hSize={this.state.hSize}
              vSize={this.state.vSize}
              cancelOverlay={() => this.props.context.setOverlay(false)}
            />,
            []
          );
          this.setState({
            stillLoading: false,
          });
        })
        .catch((e) => {
          this.props.context.setItem(
            "toasterText",
            "We could not catch that. Try reloading the page or starting over."
          );

          this.setState({
            stillLoading: false,
          });
        });
    } else {
      this.setState({
        legoedImg: "",
      });
      legofy(temp)
        .then((res) => {
          let temp = res.data.data[1];
          temp.map((e: any) => {
            e[1] = JSON.stringify(e[1]);
          });
          this.setState(
            {
              palette: temp,
              legoedImg: this.props.srcImage + "_lego_gap?" + performance.now(),
              stillLoading: false,
            },
            () => {
              this.props.context.setItem("irsDetails", this.state);
            }
          );
        })
        .catch((e) => {
          this.props.context.setItem(
            "toasterText",
            "We could not catch that. Try reloading the page or starting over."
          );
          this.props.context.setItem("irsDetails", this.state);
          this.setState({
            stillLoading: false,
          });
        });
    }
  };

  getCartId = () => {
    let i = "1";
    while (
      Object.keys(this.props.context.cart).includes(i) &&
      parseInt(i) < this.props.context.cartCount + 1
    ) {
      i = JSON.stringify(parseInt(i) + 1);
    }
    return parseInt(i);
  };

  addItemToCart() {
    let currentCart = this.props.context.cart;
    let newItem = {
      id: this.getCartId(),
      name: this.props.srcImage.split("com/")[1],
      image: this.state.legoedImg,
      dimesions: { x: this.state.hSize, y: this.state.vSize },
      price: this.state.vSize * this.state.hSize * 1900,
      quantity: 1,
      irsConfig: this.properColors(),
    };
    currentCart[this.getCartId()] = newItem;
    if (this.props.context.userName !== "") {
      updateCart(this.props.context.userName, currentCart)
        .then((res) => {
          this.props.context.setItem("cart", res.data.data);
          this.props.context.setItem(
            "cartCount",
            Object.keys(currentCart).length
          );
          this.props.context.setItem(
            "toasterText",
            "Item has been added to cart."
          );
        })
        .catch((e) => {
          this.props.context.setItem(
            "toasterText",
            "Unable to add item to cart. Please try again later."
          );
        });
    } else {
      this.props.context.setItem("cart", currentCart);
      this.props.context.setItem("cartCount", Object.keys(currentCart).length);
      this.props.context.setItem("toasterText", "Item has been added to cart.");
    }
  }

  calculateBoxSizing = (val: number) => {
    let x = (val / (this.state.vSize + this.state.hSize)) * 100;
    if (x < 30) {
      return 30;
    } else if (x > 80) {
      return 80;
    } else {
      return x;
    }
  };

  IRSImagePart = () => {
    return (
      <div className="IRS-image">
        <div className="IRS-image-container">
          <img
            id="legoedImg"
            src={this.state.legoedImg || getIcon("loading_ring.gif")}
            style={{ transform: `scale(${this.state.imageZoom / 100})` }}
            alt=""
          />
        </div>

        <div className="IRS-button-strip">
          <div
            onClick={() => {
              // this.props.initiateStartOver();
              // this.props.context.refreshIRS();
              this.props.uploadImage();
            }}
            className="black-button but-gold"
          >
            Start over
          </div>
          <div
            onClick={() => {
              this.legofication(true);
            }}
            className="black-button but-gold"
          >
            Preview
          </div>
          <div
            onClick={() => {
              this.addItemToCart();
            }}
            className="black-button but-gold"
          >
            Add to cart
          </div>
        </div>
        <div className="IRS-details-strip">
          <div className="IRS-detail-snip">
            <FaRulerCombined color="#ddb253" size={"12px"} />
            <div>
              Size: {Math.round(this.state.hSize * 25.6)} cm X{" "}
              {Math.round(this.state.vSize * 25.6)} cm
            </div>
          </div>
          <div className="IRS-detail-snip">
            <FaRupeeSign color="#ddb253" size={"12px"} />
            Price: ₹ {this.state.vSize * this.state.hSize * 1900}
          </div>
          <div className="IRS-detail-snip">
            <FaCubes color="#ddb253" size={"12px"} />
            <div>
              Pieces: {this.state.vSize * this.state.hSize * 1024} bricks
            </div>
          </div>
        </div>
      </div>
    );
  };

  IRSSizePart = () => {
    return (
      <div className="IRS-controls">
        <div
          className="IRSC-header"
          title="The size of the image is determined by the baseplate in the vertical and horizontal direction."
        >
          <FaExpandArrowsAlt size={"20px"} color={"#ddb253"} />
          Size
          <div className="info">
            <FaInfoCircle color={"white"} size={"20px"} />
          </div>
        </div>
        <div className="IRSC-body" style={{ margin: "55px 0" }}>
          <div className="size-adjust">
            <FaPlusCircle
              color={"white"}
              size={"14px"}
              className="clickable"
              onClick={() => {
                if (this.state.lastClick >= Date.now() - delay) return;
                this.setState({ lastClick: Date.now() });
                this.setState(
                  {
                    hSize:
                      this.state.hSize + 1 <= 20 ? this.state.hSize + 1 : 1,
                  },
                  () => {
                    this.legofication();
                  }
                );
              }}
            />
            <div className="centerized">
              <FaArrowsAltV color={"white"} size={"14px"} />
              &nbsp; Vertical
            </div>
            <FaMinusCircle
              color={"white"}
              size={"14px"}
              className="clickable"
              onClick={() => {
                if (this.state.lastClick >= Date.now() - delay) return;
                this.setState({ lastClick: Date.now() });
                this.setState(
                  {
                    hSize: this.state.hSize - 1 > 0 ? this.state.hSize - 1 : 20,
                  },
                  () => {
                    this.legofication();
                  }
                );
              }}
            />
          </div>
          <div className="size-val-cover centerized">
            <div
              className="size-val centerized"
              style={{
                width: this.calculateBoxSizing(this.state.vSize),
                height: this.calculateBoxSizing(this.state.hSize),
              }}
            >
              <>{this.state.hSize}</> <>X</> <>{this.state.vSize}</>
            </div>
          </div>

          <div className="size-adjust">
            <FaPlusCircle
              color={"white"}
              size={"14px"}
              className="clickable"
              onClick={() => {
                if (this.state.lastClick >= Date.now() - delay) return;
                this.setState({ lastClick: Date.now() });
                this.setState(
                  {
                    vSize:
                      this.state.vSize + 1 <= 20 ? this.state.vSize + 1 : 1,
                  },
                  () => {
                    this.legofication();
                  }
                );
              }}
            />
            <div className="centerized">
              Horizontal &nbsp;
              <FaArrowsAltH color={"white"} size={"14px"} />
            </div>
            <FaMinusCircle
              color={"white"}
              size={"14px"}
              className="clickable"
              onClick={() => {
                if (this.state.lastClick >= Date.now() - delay) return;
                this.setState({ lastClick: Date.now() });
                this.setState(
                  {
                    vSize: this.state.vSize - 1 > 0 ? this.state.vSize - 1 : 20,
                  },
                  () => {
                    this.legofication();
                  }
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  IRSColorPart = () => {
    return (
      <div className="IRS-controls">
        <div
          className="IRSC-header"
          title="Automatically select the best colours using the slider . Manually select or deselect colours from the palette."
        >
          <FaPalette size={"20px"} color={"#ddb253"} />
          Colours
          <div className="info">
            <FaInfoCircle color={"white"} size={"20px"} />
          </div>
        </div>
        <div className="IRSC-body flexed columned ali-centered">
          <div className="color-select-item">
            <div>
              <input
                type="checkbox"
                checked={this.state.autoColor}
                onChange={(ev) => {
                  if (this.state.lastClick >= Date.now() - delay) return;
                  this.setState({ lastClick: Date.now() });
                  this.setState(
                    {
                      autoColor: ev.target.checked,
                    },
                    () => {
                      this.legofication();
                    }
                  );
                }}
              />
              &nbsp;Auto color select
            </div>
          </div>
          {this.state.autoColor ? (
            <div className="color-select-item">
              <>Colors used: {this.state.colorNos}</>
              <br />
              <br />
              <>
                <input
                  type="range"
                  max={50}
                  min={1}
                  value={this.state.colorNos}
                  style={{ width: "200px" }}
                  onChange={(ev) => {
                    this.setState(
                      {
                        colorNos: parseInt(ev.target.value),
                      },
                      () => {
                        if (this.state.lastClick >= Date.now() - delay) return;
                        this.setState({ lastClick: Date.now() });
                        this.legofication();
                      }
                    );
                  }}
                />
              </>
            </div>
          ) : (
            <div
              className="color-select-item"
              style={{
                width: "200px",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              Enable "auto color select" option to automatically select the most
              accurate range of colors based on number of colors you want in
              your final product.
            </div>
          )}
          <div className="color-select-item">
            <>Select colors manually</>
            <br />
            <br />
            <div className="color-picker">
              {this.state.palette.map((e: [string, any], i: number) => {
                return (
                  <div
                    className={`each-color ${e[1] == "1" ? "selected" : ""}`}
                    style={{ backgroundColor: `rgb(${e[0]})` }}
                    onClick={() => {
                      if (this.state.lastClick >= Date.now() - delay) return;
                      this.setState({ lastClick: Date.now() });
                      let p = this.state.palette;
                      p[i] = e[1] == "1" ? [e[0], "0"] : [e[0], "1"];
                      this.setState(
                        {
                          autoColor: false,
                          palette: [...p],
                        },
                        () => {
                          this.legofication();
                        }
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  IRSEnhancePart = () => {
    return (
      <div className="IRS-controls">
        <div
          className="IRSC-header"
          title="Play around with the brightness, contrast and saturation of the image to bring out more details."
        >
          <FaMagic size={"20px"} color={"#ddb253"} />
          Enhancements
          <div className="info">
            <FaInfoCircle color={"white"} size={"20px"} />
          </div>
        </div>
        <div className="IRSC-body flexed columned ali-centered">
          <div className="color-select-item bright">
            <>Brightness: {this.state.brightness}</>
            <br />
            <br />
            <>
              <input
                type="range"
                max={100}
                min={1}
                value={this.state.brightness}
                style={{ width: "200px" }}
                onChange={(ev) => {
                  this.setState(
                    {
                      brightness: parseInt(ev.target.value),
                    },
                    () => {
                      if (this.state.lastClick >= Date.now() - delay) return;
                      this.setState({ lastClick: Date.now() });
                      this.legofication();
                    }
                  );
                }}
              />
            </>
          </div>
          <div className="color-select-item contrast">
            <>Contrast: {this.state.contrast}</>
            <br />
            <br />
            <>
              <input
                type="range"
                max={100}
                min={1}
                value={this.state.contrast}
                style={{ width: "200px" }}
                onChange={(ev) => {
                  if (this.state.lastClick >= Date.now() - delay) return;
                  this.setState({ lastClick: Date.now() });
                  this.setState(
                    {
                      contrast: parseInt(ev.target.value),
                    },
                    () => {
                      this.legofication();
                    }
                  );
                }}
              />
            </>
          </div>
          <div className="color-select-item saturate">
            <>Saturation: {this.state.saturation}</>
            <br />
            <br />
            <>
              <input
                type="range"
                max={100}
                min={1}
                value={this.state.saturation}
                style={{ width: "200px" }}
                onChange={(ev) => {
                  this.setState(
                    {
                      saturation: parseInt(ev.target.value),
                    },
                    () => {
                      if (this.state.lastClick >= Date.now() - delay) return;
                      this.setState({ lastClick: Date.now() });
                      this.legofication();
                    }
                  );
                }}
              />
            </>
          </div>
        </div>
      </div>
    );
  };

  IRSZoomPart = () => {
    return (
      <div className="IRS-controls">
        <div
          className="IRSC-header"
          title="Image zoom option used to zoom in and zoom out the internal content of the pixelated image, changing the aspect ratio while keeping the length and breadth of the pixelated image fixed. Auto fit option used to automatically fit the pixelated image with best length and breadth. Get a closer look at the pixelated image using the enlarge slider."
        >
          <FaSearch size={"20px"} color={"#ddb253"} />
          Zoom
          <div className="info">
            <FaInfoCircle color={"white"} size={"20px"} />
          </div>
        </div>
        <div className="IRSC-body flexed columned ali-centered">
          <div className="color-select-item">
            <>Image zoom (Crop/Aspect ratio): {this.state.zoomCrop}</>
            <br />
            <br />
            <>
              <input
                type="range"
                max={500}
                min={100}
                value={this.state.zoomCrop}
                style={{ width: "200px" }}
                onChange={(ev) => {
                  this.setState(
                    {
                      zoomCrop: parseInt(ev.target.value),
                    },
                    () => {
                      if (this.state.lastClick >= Date.now() - delay) return;
                      this.setState({ lastClick: Date.now() });
                      this.legofication();
                    }
                  );
                }}
              />
            </>
          </div>

          <div className="color-select-item">
            <div>
              <input
                type="checkbox"
                checked={this.state.autoFit}
                onChange={(ev) => {
                  if (this.state.lastClick >= Date.now() - delay) return;
                  this.setState({ lastClick: Date.now() });
                  this.setState(
                    {
                      autoFit: ev.target.checked,
                    },
                    () => {
                      this.legofication();
                    }
                  );
                }}
              />
              &nbsp;Auto-fit
            </div>
          </div>

          <div className="color-select-item">
            <>Image enlarge: {this.state.imageZoom / 100}</>
            <br />
            <br />
            <>
              <input
                type="range"
                max={400}
                min={100}
                step="0.2"
                value={this.state.imageZoom}
                style={{ width: "200px" }}
                onChange={(ev) => {
                  this.setState({
                    imageZoom: parseInt(ev.target.value),
                  });
                }}
              />
            </>
          </div>
        </div>
      </div>
    );
  };

  selectedControlPart = () => {
    switch (this.state.selectedControl) {
      case "size":
        return this.IRSSizePart();
      case "color":
        return this.IRSColorPart();
      case "enhance":
        return this.IRSEnhancePart();
      case "zoom":
        return this.IRSZoomPart();
      default:
        return;
    }
  };

  render() {
    return (
      <>
        {window.innerWidth <= 1000 ? (
          <div className="IRS-maker mobile">
            {this.IRSImagePart()}
            <div className="IRS-controls-selector">
              <div
                className={`IRS-controls-options ${
                  this.state.selectedControl === "size" ? "selected" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedControl: "size",
                  });
                }}
              >
                <FaExpandArrowsAlt size={"20px"} color={"#ddb253"} />
              </div>
              <div
                className={`IRS-controls-options ${
                  this.state.selectedControl === "color" ? "selected" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedControl: "color",
                  });
                }}
              >
                <FaPalette size={"20px"} color={"#ddb253"} />
              </div>
              <div
                className={`IRS-controls-options ${
                  this.state.selectedControl === "enhance" ? "selected" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedControl: "enhance",
                  });
                }}
              >
                <FaMagic size={"20px"} color={"#ddb253"} />
              </div>
              <div
                className={`IRS-controls-options ${
                  this.state.selectedControl === "zoom" ? "selected" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedControl: "zoom",
                  });
                }}
              >
                <FaSearch size={"20px"} color={"#ddb253"} />
              </div>
            </div>
            <div className="IRS-selected-control styledScroll">
              {this.selectedControlPart()}
            </div>
          </div>
        ) : (
          <div className="IRS-maker desktop">
            <div className="IRS-desktop-controls">
              <div>{this.IRSSizePart()}</div>
              <div>{this.IRSColorPart()}</div>
            </div>
            {this.IRSImagePart()}
            <div className="IRS-desktop-controls">
              <div>{this.IRSEnhancePart()}</div>
              <div>{this.IRSZoomPart()}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default IRS;
