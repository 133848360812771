import React, { useState } from 'react'
import { blogDetails } from '../../APIs/API'

type Props = {
  data: blogDetails;
}

const BlogItem = (props: Props) => {
  const [see,setSee] = useState(true);
  return (
    <div className='blog-item' >
      <meta name="keywords" content={props.data.meta} />
      <meta name="description" content={props.data.info} />
        <div className="blog-title">{props.data.title}</div>
        <img src={props.data.image} alt={props.data.title} className="blog-image" />
        <div className={`blog-info ${see?'see-more':''}`} dangerouslySetInnerHTML={{__html: props.data.info}} />
        {props.data.info.length>800?see?<center className='clickable' onClick={()=>setSee(false)} style={{fontSize: '12px', margin: '10px'}}>See more</center>:<center className='clickable' onClick={()=>setSee(true)} style={{fontSize: '12px', margin: '10px'}}>See less</center>:null}
    </div>
  )
}

export default BlogItem