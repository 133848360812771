import React, { Component } from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";
import { getImg, imageExists } from "../../Components/Utility/getImg";
import { Link } from "react-router-dom";
import IRS from "./IRS";
import { getSignedRequest } from "../../APIs/API";
import Login from "../Landing/Login";

type Props = {
  context: any;
  changeHistory: Function;
};

type State = {
  imageFile: any;
  irsActive: boolean;
  lastRun: any;
};

const delay = 9000;

class IRSEntry extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      imageFile: null,
      irsActive: this.props.context.irsImg,
      lastRun: 0,
    };
  }

  componentDidMount(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  initiateStartOver = () => {
    this.setState({
      imageFile: null,
    });
  };

  onUpload = (image: string) => {
    this.props.context.setItem("irsImg", image);
    this.uploadImage(image);
  };

  waitForImageDump = (image: string) => {
    if (this.state.lastRun >= Date.now() - delay) {
      return false;
    }
    this.setState({ lastRun: this.state.lastRun + 5 });
    if (imageExists(image)) return true;
    else return false;
  };

  uploadIntiated = () => {
    let x = document.getElementById("uploadInput") as HTMLInputElement;
    if (x && x.files) {
      return true;
    } else return false;
  };

  uploadImage = (image?: any, func?: Function) => {
    this.setState({
      irsActive: false,
    });
    this.props.context.setOverlay(
      true,
      "Upload Image (File size should not exceed 3MB)",
      <div className="image-upload">
        <div style={{ textAlign: "center" }}>
          Drag and drop or click here
          <br />
          <span id="uploadWarn" />
        </div>

        <img
          id="uploadedImage"
          src={
            this.state.imageFile
              ? URL.createObjectURL(this.state.imageFile[0])
              : getImg("imageupload.png")
          }
        />
        <input
          type="file"
          id="uploadInput"
          // onClick={() => {
          //   this.props.context.setItem(
          //     "toasterText",
          //     "Please wait for the file to be uploaded."
          //   );
          // }}
          onChange={(ev) => {
            if (ev.target.files) {
              // console.log(ev.target.files);
              if (Math.round(ev.target.files[0].size / (1024 * 1024)) > 3) {
                ev.preventDefault();
                this.props.context.setItem(
                  "toasterText",
                  "Image was too large. Please try uploading an image under 3MB."
                );
                return;
              }
              this.setState(
                {
                  imageFile: ev.target.files,
                },
                () => {
                  let x = document.getElementById(
                    "uploadWarn"
                  ) as HTMLSpanElement;
                  x.textContent =
                    "Please do not click away while the image is being uploaded";
                  this.props.context.setItem(
                    "toasterText",
                    "Please wait for the file to be uploaded."
                  );
                }
              );
              getSignedRequest(ev.target.files[0])
                .then((data) => {
                  while (!this.waitForImageDump(data)) {}
                  this.onUpload(data);
                })
                .catch((e) => {
                  this.props.context.setItem(
                    "toasterText",
                    "Image was not uploaded. Please try again later."
                  );
                });
            }
          }}
        />
      </div>,
      [
        {
          title: "Cancel",
          onClick: () => {
            this.props.context.setOverlay(false);
          },
        },
        {
          title: "Change",
          onClick: () => {
            document.getElementById("uploadInput")?.click();
          },
          inactive: false,
        },
        {
          title: "Next",
          onClick: () => {
            this.props.context.setOverlay(false);
            this.setState(
              {
                irsActive: true,
              },
              () => {
                if (!this.waitForImageDump(image)) {
                  this.props.context.setItem(
                    "toasterText",
                    "Image is being uploaded. Please wait and try again."
                  );
                }
                if (func) {
                  func();
                }
              }
            );
          },
          inactive: image ? false : true,
        },
      ],
      true
    );
  };

  render() {
    return (
      <>
        {this.state.irsActive ? (
          // this.props.context.userName ? (
          <IRS
            context={this.props.context}
            uploadImage={this.uploadImage.bind(this)}
            srcImage={this.props.context.irsImg}
            initiateStartOver={this.initiateStartOver.bind(this)}
          />
        ) : (
          // ) : (
          // <div style={{ minHeight: "50vh" }}>
          //   <FadeInSection>
          //     <Login
          //       login={(uName: string, pass: string) => {
          //         this.props.context.login(uName, pass);
          //         this.setState({
          //           irsActive: false,
          //         });
          //       }}
          //       toaster={this.props.context.toasterText}
          //     />
          //   </FadeInSection>
          // </div>
          // )
          <div className="koha-lander koha-static">
            <FadeInSection>
              <div className="page-img-headers">
                <img
                  style={{ opacity: 0.6, maxHeight: "450px" }}
                  src={getImg("customizationpageimage.jpg")}
                />
                <div
                  className="overlay-text"
                  style={{ fontSize: "48px", color: "#ddb253" }}
                >
                  INSPIRE . CREATE . EXHIBIT
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              <div className="lander-quotes">
                Your Journey into the World of Pixels starts here!
              </div>
            </FadeInSection>

            <FadeInSection>
              <div className="lander-how-to">
                <div
                  className="video-holder clickable"
                  onClick={() => {
                    this.uploadImage();
                    // if (this.props.context.userName) this.uploadImage();
                    // else
                    //   this.setState({
                    //     irsActive: true,
                    //   });
                  }}
                >
                  <img src={getImg("midsidephoto.png")} />
                </div>
                <div className="video-holder-right">
                  <img src={getImg("art-image-bg.jpg")} />
                  <div id="guidelines">
                    <div className="para-header">Koha Gallery</div>
                    <div style={{ margin: "auto 20px" }}>
                      Not sure what photo to upload? You can use our specially
                      curated pictures from our Gallery. Piece of ART bound to
                      add beauty and create a focal point in any space.
                    </div>
                  </div>
                  <div
                    className="know-but"
                    style={{ margin: "20px auto" }}
                    onClick={() => {
                      this.props.changeHistory("/gallery");
                    }}
                  >
                    Know more
                  </div>
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              <div
                className=" clickable"
                style={{
                  textAlign: "center",
                  margin: "30px auto",
                  fontSize: "20px",
                }}
              >
                <Link to="/howitworks">
                  Click to read our Tips and Tricks to select the best pictures
                  to Pixelate.
                </Link>
              </div>
            </FadeInSection>

            <FadeInSection>
              <div className="page-img-footers centerized">
                <img
                  id="galleryPreview"
                  src={getImg("uploadfooterbanner.png")}
                />

                <div className="overlay-text">
                  Got a project or artwork in mind?
                  <br />
                  Talk to us.
                  <br />
                  We support every step of the creative process!
                  <br />
                  <div
                    className="black-button lander-quotes"
                    onClick={() => {
                      this.props.changeHistory("/contact");
                    }}
                  >
                    Contact us
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        )}
      </>
    );
  }
}

export default IRSEntry;
