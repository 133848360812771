import React from "react";
import { FadeInSection } from "../../Components/Functional/FadeInSection";

type Props = {};

export const Shipping = (props: Props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="koha-static">
      <FadeInSection>
        <div className="para-header">Shipping Information</div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Fulfillment and Processing Times</h3>
          <p>
            All orders are dispatched from our head office in Bangalore, India.
            <br />
            Orders are processed within 3 business days. Orders are not
            dispatched on weekends or holidays.
            <br />
            If we are experiencing a high volume of orders, shipments may be
            delayed by a few days and we are sorry for any inconvenience caused
            due to the same.
            <br />
            Please allow additional days in transit for delivery. In the event
            of a significant delay in the shipment, we will contact you via
            email or telephone. Delays in delivery are occasionally expected to
            occur.
            <br />
            Tracking ID and the link will be sent to your registered email once
            the order has been dispatched. Please check your inbox from time to
            time.
            <br />
            We offer free shipping across India.
          </p>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Shipping Rates & Delivery Times</h3>
          <p>
            Shipping charges for your order will be calculated and displayed at
            checkout. (Free accross India)
            <br />
            We ship via ShipRocket for both domestic and international orders.
            In some cases, for international orders can be shipped via other
            shipping solutions. <br />
            You will receive a shipment confirmation email containing your
            tracking number(s) once order has been shipped. Please track the
            progress of your orders using the tracking number.
          </p>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Damages</h3>
          <p>
            At Koha, products are packed and dispatched with utmost care and
            love. All the products are closely inspected for defects and damages
            before it leaves our facility. We adhere to our strict quality
            control practices. If you receive the product damaged, please get in
            touch with us by sending an email to "support@thekoha.com" and
            include photos of the damaged product. Products will be replaced if
            there are any damages to the baseplate or backboard.
            <br />
            <b>We do not process refunds in any case.</b>
          </p>
        </div>
      </FadeInSection>

      <FadeInSection>
        <div className="static-para">
          <h3>Prices, Customs, Duties and Taxes</h3>
          <p>
            All prices on the website are in Indian Rupees and are exclusive of
            GST. Taxes are calculated at the checkout.
            <br /> Prices are subject to change without notice.
            <br />
            We are not responsible for any customs and duties applied to your
            order. All fees imposed during or after shipping are the
            responsibility of the customer (tariffs, taxes, etc.).
            <br />
            For further enquiry or queries, drop an Email to us at
            support@thekoha.com
          </p>
        </div>
      </FadeInSection>
    </div>
  );
};
