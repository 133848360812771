import React, { useEffect, useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import { addCoupon, couponType, updateCoupon } from "../../../APIs/API";

type Props = {
  context: any;
  refresh: Function;
  data?: couponType;
};

const AddCoupon = (props: Props) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [disc, setDisc] = useState("");

  const checkForm = () => {
    //do validations
    if (name !== "" && desc !== "" && disc !== "") return true;
    else return false;
  };

  useEffect(() => {
    if (props.data) {
      setName(props.data?.name);
      setDesc(props.data?.description);
      setDisc(JSON.stringify(props.data?.discount));
    }
  }, [props.data]);

  return (
    <div className="add-modal">
      <div>Coupon Name/Code</div>
      <input
        autoComplete="off"
        type="text"
        value={name}
        onChange={(ev) => {
          setName(ev.target.value);
        }}
      />

      <div>Description</div>
      <input
        autoComplete="off"
        type="text"
        value={desc}
        onChange={(ev) => {
          setDesc(ev.target.value);
        }}
      />

      <div>Discount offer</div>
      <input
        autoComplete="off"
        type="number"
        value={disc}
        onChange={(ev) => {
          setDisc(ev.target.value);
        }}
      />

      <div
        className={`black-button ${
          checkForm() ? "" : "unclickable uneventful blur"
        }`}
        onClick={() => {
          if (props.data) {
            updateCoupon({
              coupon_id: props.data.coupon_id,
              name: name,
              description: desc,
              discount: parseInt(disc),
              status: true,
            }).then((res: any) => {
              if (res.data.success === true) {
                props.context.setItem("toasterText", "Coupon update.");
                props.refresh();
                props.context.setOverlay(false);
              } else {
                props.context.setItem(
                  "toasterText",
                  "Something went wrong. Please try again later."
                );
                props.refresh();
                props.context.setOverlay(false);
              }
            });
          } else {
            addCoupon({
              coupon_id: "",
              name: name,
              description: desc,
              discount: parseInt(disc),
              status: true,
            }).then((res: any) => {
              if (res.data.success === true) {
                props.context.setItem("toasterText", "Coupon added.");
                props.refresh();
                props.context.setOverlay(false);
              } else {
                props.context.setItem(
                  "toasterText",
                  "Something went wrong. Please try again later."
                );
                props.refresh();
                props.context.setOverlay(false);
              }
            });
          }
        }}
      >
        <BiUserPlus size="20px" /> {props.data ? "Update" : "Add"}
      </div>
    </div>
  );
};

export default AddCoupon;
