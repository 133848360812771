import React from "react";
import { FaCubes } from "react-icons/fa";

type Props = {
  fullScreen?: boolean;
};

export const Loader = (props: Props) => {
  return (
    <div
      className={`koha-loader centerized ${
        props.fullScreen ? "fullScreen" : ""
      }`}
    >
      <FaCubes color="#ddb253" size={"50px"} />
    </div>
  );
};
