import React, { Component } from "react";
import { FaRegUser } from "react-icons/fa";
import ChangePass from "./ChangePass";
// import EditAccount from "./EditAccount";
import MyOrders from "./MyOrders";
// import MyWishlist from "./MyWishlist";

type Props = {
  context: any;
  changeHistory: Function;
};

type State = {
  profileTab: string;
};

type navItem = { Name: string; nav: string };

const navItems: navItem[] = [
  { Name: "My orders", nav: "orders" },
  // { Name: "My wishlist", nav: "wish" },
  // { Name: "Edit account", nav: "edit" },
  { Name: "Change password", nav: "password" },
  { Name: "Delete account", nav: "delete" },
  { Name: "Logout", nav: "logout" },
];

class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      profileTab: "orders",
    };
  }

  componentDidMount(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    document.getElementsByClassName("koha-layout")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  kpNavItem = (selected: boolean, nav: navItem, i: number) => {
    return (
      <div
        key={i}
        className={`kp-nav-item ${selected ? "selected" : ""}`}
        onClick={() => {
          if (nav.nav === "delete") {
            this.props.context.setOverlay(
              true,
              "Are you sure you want to delete your account?",
              null,
              [
                { title: "Yes", onClick: () => null },
                {
                  title: "No",
                  onClick: () => {
                    this.props.context.setOverlay(false);
                  },
                },
              ]
            );
          } else if (nav.nav === "logout") {
            this.props.context.setOverlay(
              true,
              "Would you like to logout?",
              null,
              [
                {
                  title: "Yes",
                  onClick: () => {
                    this.props.context.logout();
                    this.props.changeHistory("/");
                  },
                },
                {
                  title: "No",
                  onClick: () => {
                    this.props.context.setOverlay(false);
                  },
                },
              ]
            );
          } else this.setState({ profileTab: nav.nav });
        }}
      >
        {nav.Name}
      </div>
    );
  };

  kpContent = () => {
    switch (this.state.profileTab) {
      // case "wish":
      //   return <MyWishlist context={this.props.context} />;
      // case "edit":
      //   return <EditAccount context={this.props.context} />;
      case "password":
        return <ChangePass context={this.props.context} />;
      case "delete":
      case "logout":
      case "orders":
      default:
        return <MyOrders context={this.props.context} />;
    }
  };

  render() {
    return (
      <div className="koha-profile">
        <div className="kp-left">
          <div className="kp-profile-details">
            <FaRegUser color="#ddb253" size="70px" />
            <h3>{this.props.context.userName}</h3>
          </div>
          <div className="kp-navigation">
            {navItems.map((e: navItem, i: number) => {
              return this.kpNavItem(e.nav === this.state.profileTab, e, i);
            })}
          </div>
        </div>

        <div className="kp-content">{this.kpContent()}</div>
      </div>
    );
  }
}

export default Profile;
